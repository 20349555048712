import React, {useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {Dialog, DialogFooter} from "../../components/dialog";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {PanelContent} from "../../components/panel";

export function ReatribuirOrdemServico({ordensServico, onModalClose}) {

	const {usuario} = useAuth();
	const [visible, setVisible] = useState(true);
	const [loading, setLoading] = useState(0);
	const [responsavel, setResponsavel] = useState(usuario);

	function handleFechar() {
		setVisible(false);
	}

	async function reatribuirOrdensServico() {
		setLoading(1);
		for (const ordemServico of ordensServico) {
			await ordemServicoService.reatribuir({ordemServico: ordemServico.id, operador: usuario, responsavel});
		}
		if (onModalClose) {
			onModalClose();
		}
		setLoading(0);
		handleFechar();
	}

	function handleChange(event) {
		setResponsavel(event.value);
	}

	return (
		<Dialog visible={visible} style={{width: "450px"}} header="Reatribuir Ordens de Serviço" onHide={handleFechar}>
			<PanelContent>
				<UsuarioAutoComplete col={12} label="Responsável" name="responsavel" value={responsavel} onChange={handleChange}/>
			</PanelContent>
			<DialogFooter>
				<Button disabled={!responsavel?.id} loading={loading === 1} label="Confirmar" icon="fa-solid fa-users" success onClick={reatribuirOrdensServico}/>
				<Button label="Fechar" icon="fa-solid fa-arrow-left" secondary onClick={handleFechar}/>
			</DialogFooter>
		</Dialog>
	);

}
