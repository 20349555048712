import React, {useState} from 'react';
import {AutoComplete} from '.';
import {empresaService} from '../../service/empresaService';

export function EmpresaAutoComplete(props) {

    const [empresas, setEmpresas] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState('')
    

    function completeMethod(event) {
        const query = []
        if (event.query.length) query.push(`textoDeBusca=${encodeURIComponent(event.query)}`);

        
       query.push("status==ATIVO")
        
        
        empresaService.autocomplete(query).then(res => {
            setEmpresas(res)
            if(!res[0]){
                setEmptyMessage('Nenhum registro encontrado')
            }else{
                setEmptyMessage('')
            }
        });
    }

    return (
        <AutoComplete
            col={3}
            placeholder="CNPJ (somente nº), nome fantasia ou razão social"
            label="Empresa"
            name="empresa"
            value={props.value}
            onChange={props.onChange}
            completeMethod={completeMethod}
            suggestions={empresas}
            invalid={emptyMessage}
            icon="fa-solid fa-user"
            {...props}
        />
    )
}
