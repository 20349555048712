import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {PanelContent} from "../../components/panel";
import {DialogFooter} from "../../components/dialog";
import {Button} from "../../components/button";
import {InputText} from "../../components/inputtext";
import {eventoService} from "../../service/eventoService";
import {useAuth} from "../../context/AuthContext";

export function RegistrarEvento({onModalClose}) {

	const {usuario} = useAuth();
	const [visible, setVisible] = useState(true);
	const [loading, setLoading] = useState(0);
	const [evento, setEvento] = useState({...eventoService.criar(), usuario: usuario.email, tipo: "REGISTRO_ACOMPANHAMENTO"});

	function handleClose() {
		setVisible(false);
	}

	function handleChange(event) {
		setEvento(prevEvento => ({...prevEvento, [event.name]: event.value}));
	}

	async function handleSalvar() {
		if (onModalClose) {
			setLoading("Salvar");
			await onModalClose(evento);
			setLoading(0);
		}
		handleClose();
	}

	return (
		<Dialog style={{width: "650px"}} visible={visible} onHide={handleClose} header="Registrar Evento">
			<PanelContent>
				<InputText multiline style={{height: "13em"}} col={12} name="observacoes" label="Detalhamento do Evento" value={evento.observacoes} onChange={handleChange}/>
			</PanelContent>
			<DialogFooter>
				<Button disabled={loading} loading={loading === "Salvar"} success icon="fas fa-save" label="Confirmar" onClick={handleSalvar}/>
				<Button secondary icon="fas fa-times" label="Fechar" onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}
