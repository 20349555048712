import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Button} from "../../components/button";
import {useBreakpoint} from "../../context/BreakpointContext";
import {Dropdown} from "../../components/dropdown";
import {InputText} from "../../components/inputtext";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {buildValidator, isEntityRequired} from "../../utils/fieldValidator";
import {ordemServicoEtapaService} from "../../service/ordemServicoEtapaService";
import {useDialog} from "../../utils/dialogContext";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {useAuth} from "../../context/AuthContext";
import {statusConsumo} from "../../service/ordemServicoService";
import {ConfirmacaoEstoquePecaCampo} from "../__Commons-OrdemServico/ConfirmacaoEstoquePecaCampo";

export function EditarAcompanhamento({compras, ordemServico, acompanhamento, setAcompanhamento, onSalvar, onVoltar, multiple}) {

	const {showDialog} = useDialog();
	const {breakpoint} = useBreakpoint();
	const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
	const {usuario} = useAuth();
	const [optionsEtapas, setOptionsEtapas] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState(buildValidator());

	useEffect(() => {
		const acompanhamento = {};
		const optionsEtapas = ordemServico.etapas.filter(e => ordemServico.etapa.proximas.some(p => e.sequencia === p)).map(e => {
			if (statusConsumo.includes(e.status)) {
				return {
					label: <><i className="fa-solid fa-database"/> {e.descricao}</>,
					value: e
				};
			}
			return {label: e.descricao, value: e};
		}) || [];
		if (compras) {
			acompanhamento.etapa = optionsEtapas.filter(e => e.value.status === "COMPRAS")[0].value;
		} else {
			if (ordemServico.etapa.proximaPadrao) {
				const proximaOption = optionsEtapas.filter(e => e.value.sequencia === ordemServico.etapa.proximaPadrao);
				if (proximaOption.length) {
					acompanhamento.etapa = proximaOption[0].value;
				}
			}
		}
		if (optionsEtapas.length === 1) {
			acompanhamento.etapa = optionsEtapas[0].value;
		}
		setOptionsEtapas(optionsEtapas);
		setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, ...acompanhamento}));
	}, [ordemServico]);

	function doSalvar(ordemServico) {
		setLoading(true);
		acompanhamento.atribuinte = usuario;
		if (onSalvar) {
			onSalvar(acompanhamento, ordemServico);
		}
	}

	function handleSalvar() {
		let messages = ordemServicoEtapaService.validarAcompanhamento(acompanhamento);
		if (messages.isEmpty()) {
			if (ordemServico.status !== "CANCELADA" && statusConsumo.includes(acompanhamento.etapa?.status)) {
				if (ordemServico.itens?.some(osi => osi.pecas.some(osip => !["MOVIMENTADO", "CANCELADO"].includes(osip.status)))) {
					showDialog(<InformationDialog header="Informação" message={`Não é possível avançar esta ordem de serviço para ${acompanhamento.etapa.descricao} pois ainda possui peças solicitadas.`}/>);
					return;
				}
				if (ordemServico.operacao === "CAMPO" && ordemServico.itens?.some(osi => osi.pecas?.some(osip => osip.status !== "CANCELADO" && !osip.estoqueConsumoConfirmado))) {
					showDialog(<ConfirmacaoEstoquePecaCampo ordemServico={ordemServico} onConfirm={doSalvar}/>);
					return;
				}
			}
			if (acompanhamento.etapa?.status === "FATURAMENTO") {
				if (multiple) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar várias ordem de serviço para faturamento ao mesmo tempo."/>);
					return;
				}
				if (ordemServico.operacao === "LABORATORIO" && !ordemServico.notaFiscal?.numero) {
					showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar a ordem de serviço para faturamento sem nota fiscal de entrada."/>);
					return;
				}
			}
			setLoading(true);
			acompanhamento.atribuinte = usuario;
			doSalvar(ordemServico);
		} else {
			setMessages(messages);
			showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
		}
	}

	function handleChange(event) {
		setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, [event.name]: event.value}));
	}

	return (
		<Panel header={multiple ? "Detalhes de Acompanhamento" : `Detalhes de Acompanhamento da ${ordemServico.numero ? "" : "Nova"} Ordem de Serviço ${ordemServico.numero || ""}`}>
			<PanelContent>
				<Dropdown onBlur={() => {
					messages.etapa = isEntityRequired(acompanhamento.etapa);
					setMessages({...messages});
				}} required invalid={messages.etapa} emptyMessage="Nenhuma etapa disponível" options={optionsEtapas} col={4} label="Etapa" name="etapa" value={acompanhamento.etapa} onChange={handleChange}/>
				<UsuarioAutoComplete onBlur={() => {
					messages.responsavel = isEntityRequired(acompanhamento.responsavel);
					setMessages({...messages});
				}} invalid={messages.responsavel} required col={8} label="Responsável" name="responsavel" value={acompanhamento.responsavel} onChange={handleChange}/>
				<InputText multiline style={{height: "13em"}} col={12} name="observacoes" label="Observações" value={acompanhamento.observacoes} onChange={handleChange}/>
			</PanelContent>
			<PanelFooter>
				<Button disabled={loading} loading={loading} icon="pi pi-save" success label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice.toString()}/>
				<Button disabled={loading} icon="pi pi-arrow-left" secondary label={isLargeDevice ? "Voltar" : null} onClick={() => (onVoltar && onVoltar()) || setAcompanhamento(null)} autowidth={!isLargeDevice.toString()}/>
			</PanelFooter>
		</Panel>
	);

}
