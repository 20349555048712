import React from "react";
import { Dropdown } from "../dropdown";
import { contratoService } from "../../service/contratoService";
import { useQuery } from "@tanstack/react-query";

const optionAvulso = {
  label: (
    <>
      <i style={{ color: "#F44336" }} className="fas fa-file-circle-xmark" />{" "}
      Avulso
    </>
  ),
  value: "",
};

function printContrato(c) {
  return {
    label: (
      <>
        <i style={{ color: "#FFC107" }} className="fas fa-star" />{" "}
        {c.autoComplete}
      </>
    ),
    value: c,
  };
}

export function ClienteContratoDropdown(props) {
  function printContratos(c) {
    if (props.value?.id && props.os) {
      c = c.filter((c) => c.id !== props.value.id);
      return [
        optionAvulso,
        printContrato(props.value),
        ...c.map(printContrato),
      ];
    } else {
      return [optionAvulso, ...c.map(printContrato)];
    }
  }

  const query = useQuery({
    queryKey: [
      "ClienteContratoDropdown",
      props.cliente?.id,
      props.serial,
      props.equipamento?.produto?.codigo,
    ],
    queryFn: async () => {
      if (props.cliente) {
        let contratos = [];
        if (props.tipo === "PROJETO") {
          console.log("Projeto");
          contratos = await contratoService
            .listarProjetosAtivos(
              props.cliente.codigo || props.cliente.codigoOmega
            )
            .then(printContratos);
        } else {
          contratos = await contratoService
            .listarContratosAtivos(
              props.cliente.id,
              props.equipamento?.serial,
              props.equipamento?.produto?.codigo
            )
            .then(printContratos);
        }
        if (props.autoSelect) {
          setTimeout(() => {
            switch (contratos.length) {
              case 2:
                if (
                  props.serial?.length ||
                  props.equipamento?.produto?.codigo
                ) {
                  props.onChange({
                    name: props.name || "contrato",
                    index: props.index,
                    value: contratos[1].value,
                  });
                }
                break;
              default:
                props.onChange({
                  name: props.name || "contrato",
                  index: props.index,
                  value: null,
                });
                break;
            }
          }, 50);
        }
        return contratos;
      }
      return [optionAvulso];
    },
  });

  function handleChange(event) {
    props.onChange({ ...event, value: event.value?.id ? event.value : null });
  }

  return (
    <Dropdown
      dataKey="id"
      label="Contrato"
      name="contrato"
      options={query.data}
      {...props}
      onChange={handleChange}
      value={props.value || ""}
    />
  );
}
