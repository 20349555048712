import {buildValidator, isEntityRequired, isRequired} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/equipamentos";

export const equipamentoService = {
	criar: () => {
		return {
			id: null,
			_key: Math.random(),
			descricao: null,
			serial: null,
			produto: null,
			cliente: null,
			setor: null,
			numeroAtivo: null,
			partNumber: null
		};
	},
	validar: equipamento => {
		const messages = buildValidator();
		messages.serial = isRequired(equipamento.serial);
		messages.produto = isEntityRequired(equipamento.produto);
		return messages;
	},
	verificarPath: async (path) => {
		return api.get(`${resourceUrl}/verificar-path`, {params: {path: path}}).then(res => res.data);
	},

	async findBySerial(serial) {		
		const params = [];
        params.push(`serial=${serial}`);
		return await api.get(`${resourceUrl+'/serial'}?${params.join("&")}`).then(response => response.data);
	},

	// async findBySerial(serial) {
	// 	return api.get(`${resourceUrl}/serial/${serial}`).then(response => response.data);
	// },
	...baseService(resourceUrl),
    async importarEquipamentos(criterio) {
		return await api.post(`${resourceUrl}/importar`, criterio).then(res => res.data);
    },
	async gerarModeloCsvImportacao() {
		return await api.get(`${resourceUrl}/modelo-importacao`, {responseType: "blob"}).then(res => res.data);
	},
	criarBackup() {
		return {
			equipamento: equipamentoService.criar(),
			dataEnvio: null,
			dataRetorno: null,
			observacoes: null
		};
	},
	async listarEquipamentos(params) {
		const query = [];
		if (params.textoDeBusca?.length) query.push(`textoDeBusca=${params.textoDeBusca}`);
		if (params.serial?.length) query.push(`serial=${params.serial}`);
		if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
		if (params.produto) query.push(`produto=${params.produto.id}`);
		if (!params.visualizarDesativados) {
			query.push("status==ATIVO");
		}
		return await equipamentoService.listar(query);
	}
};
