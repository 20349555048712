import React, {useContext} from "react";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {Foto} from "../../components/foto";
import {toSrc} from "../../service/fotoUtils";
import {PanelContent} from "../../components/panel";
import {DialogContext} from "../../utils/dialogContext";
import {iconStatus, labelStatus} from "../../service/ordemServicoService";
import {useAuth} from "../../context/AuthContext";
import {Observacao} from "./Observacao";

export function FotoVideo({ordemServico, setOrdemServico}) {

	const {usuario} = useAuth();
	const {showDialog} = useContext(DialogContext);

	function handleFoto(event) {
		showDialog(<Observacao message="Adicione um comentário breve (ou clique em OK para fechar)" onModalClose={texto => {
			setOrdemServico(prevOrdemServico => {
				prevOrdemServico.itens[0].fotos.push({
					status: ordemServico.status,
					responsavel: usuario,
					fileName: event.conteudo.fileName,
					contentType: event.conteudo.contentType,
					texto,
					conteudo: {
						conteudo: event.conteudo.content
					}
				});
				return {...prevOrdemServico, _alterado: true};
			});
		}}/>);
	}

	function excluirFoto(index) {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir esta foto?" onYes={() => {
			setOrdemServico(prevOrdemServico => {
				prevOrdemServico.itens[0].fotos.splice(index, 1);
				return {...prevOrdemServico};
			})
		}}/>);
	}

	function renderFoto(f, index) {
		return (
			<div className="md:col-4 sm:col-12 xm" style={{position: "relative", fontSize: ".9em"}}>
				<Foto contentType={f.contentType} thumb accept="image/jpeg, image/png, image/bmp, video/mp4, video/webm, video/mpeg, video/x-ms-wmv, video/x-msvideo, video/x-ms-asf" readOnly={ordemServico.itens[0].fotos[index]?.id} value={toSrc(ordemServico.itens[0].fotos[index])} onChange={conteudo => handleFoto({conteudo, index})} onDelete={() => excluirFoto(index)}/>
				<div className="foto-status">
					{iconStatus[f.status]} {labelStatus[f.status]}<br/>
					{f.responsavel?.nome}<br/>
					{f.texto?.length ? <i>"{f.texto}"</i> : null}
				</div>
			</div>
		);
	}

	let listFotos = [...ordemServico.itens[0].fotos, {}];

	return (
		<PanelContent>
			{listFotos.map(renderFoto)}
		</PanelContent>
	);

}
