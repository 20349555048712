import React from "react"

export const headerTemplate = (title, icon) => {
    return (
        <div className="p-panel-header">
            <div className="flex align-items-center">
                <i className={`fa-solid ${icon} mr-2`} />
                <div className="font-bold">{title}</div>
            </div>
        </div>
    );
}
