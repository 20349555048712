import {buildValidator, isRequired} from "../utils/fieldValidator";
import {api} from "./api";
import {baseService} from "./baseService";

const resourceUrl = "/ordens-servico/fluxos";

export const ordemServicoFluxoService = {
    criar: () => {
        return {
            id: null,
            _key: Math.random(),
            descricao: "",
            etapas: []
        };
    },
    criarEtapa: () => {
        return {
            id: null,
            _key: Math.random(),
            descricao: "",
            status: null
        };
    },
    validar(fluxo) {
        const messages = buildValidator();
        messages.descricao = isRequired(fluxo.descricao);
        messages.etapas = [...Array(fluxo.etapas.length)].map((_, i) => ({
            descricao: isRequired(fluxo.etapas[i].descricao)
        }));
        return messages;
    },
    verificarPath: async (path) => {
        return api.get(`${resourceUrl}/verificar-path`, {
            params: {path: path}
        }).then(res => res.data);
    },
    ...baseService(resourceUrl),
	buscarFluxoLaboratorio() {
		return api.get(`${resourceUrl}/laboratorio`).then(response => response.data);
	},
    async listarFluxos(params) {
        const query = [];
        if (params.descricao?.length) query.push(`descricao=${params.descricao}`);
        return await ordemServicoFluxoService.listar(query);
    }
};
