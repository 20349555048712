import React from "react";
import {Column} from "primereact/column";
import {iconStatus, labelStatus} from "../../service/ordemServicoService";
import moment from "moment";
import {formatMinutes} from "../../utils/numberFormatter";
import {DataTable} from "../../components/datatable";
import {renderPosicaoPrevisaoAtendimento} from "../OrdensServico/EditarOrdemServico";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const previsoesAtendimentoHeaderGroup = (
    <ColumnGroup>
        <Row>
            <Column rowSpan={2} style={{width: "3em"}}/>
            <Column rowSpan={2} header="Nome do Termo"/>
            <Column colSpan={2} header="Status"/>
            <Column rowSpan={2} style={{width: "13em"}} header="Início"/>
            <Column colSpan={2} header="Previsão"/>
            <Column rowSpan={2} style={{width: "13em"}} header="Conclusão"/>
            <Column rowSpan={2} header="Δt" style={{width: "6em"}}/>
        </Row>
        <Row>
            <Column header="Inicial"/>
            <Column header="Final"/>
            <Column header="Concluir Até" style={{width: "13em"}}/>
            <Column header="Tempo Restante" style={{width: "13em"}}/>
        </Row>
    </ColumnGroup>
);

export function SLA({ordemServico}) {

    return (
        <DataTable headerColumnGroup={previsoesAtendimentoHeaderGroup} value={ordemServico.previsoesAtendimento}>
            <Column style={{textAlign: "center"}} header="Posição" field="posicao" body={renderPosicaoPrevisaoAtendimento}/>
            <Column header="Nome do Termo" field="descricao" body={ospa => ospa.descricao}/>
            <Column header="Status Inicial" field="statusInicial" body={ospa => <span>{iconStatus[ospa.statusInicial]} {labelStatus[ospa.statusInicial]}</span>}/>
            <Column header="Status Final" field="statusFinais" body={ospa => ospa.statusFinais.map(sf => <span>{iconStatus[sf]} {labelStatus[sf]}</span>)}/>
            <Column style={{width: "13em", textAlign: "center"}} header="Início" field="inicio" body={ospa => moment(ospa.inicio).format("DD/MM/YYYY HH:mm")}/>
            <Column style={{width: "13em", textAlign: "center"}} header="Prazo" field="previsao" body={ospa => moment(ospa.previsao).format("DD/MM/YYYY HH:mm")}/>
            <Column header="Tempo Restante" field="tempoRestante" style={{textAlign: "center"}} body={ospa => {
                const minutes = moment(ospa.previsao).diff(ospa.fim || moment(), "minutes");
                return <span style={{color: minutes > 0 ? "inherit" : "#F44336"}}>{minutes < 0 ? "[-] " : ""}{formatMinutes(Math.abs(minutes))}</span>;
            }}/>
            <Column style={{width: "13em", textAlign: "center"}} header="Conclusão" field="fim" body={ospa => ospa.fim && moment(ospa.fim).format("DD/MM/YYYY HH:mm")}/>
            <Column header="Δt" field="intervalo" style={{textAlign: "center"}} body={ospa => {
                if (ospa.inicio && ospa.fim) {
                    return formatMinutes(Math.abs(moment(ospa.inicio).diff(moment(ospa.fim), "minutes")));
                }
                return "";
            }}/>
        </DataTable>
    );

}
