import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import { TabPanel } from 'primereact/tabview';
import { toast } from 'react-toastify';

import { EditarDadosPrincipais } from './EditarDadosPrincipais';
import { useAuth } from "../../context/AuthContext";
import { empresaService } from '../../service/empresaService';
import { FormTab } from '../../components/formtab';
import { PanelContent } from '../../components/panel';
import { buildValidator} from '../../utils/fieldValidator';
import { arquivoService } from '../../service/arquivoService';


export function EditarEmpresa() {

    let { id } = useParams();
    const {roles} = useAuth()
    const navigate = useNavigate()

    //fields
    const [empresa, setEmpresa] = useState(empresaService.criar);
    const [messages, setMessages] = useState(buildValidator());
    const [loading, setLoading] = useState(false);

    //useEffects
    useEffect(() => {
        if(!id) return;

        if(id === 'new') return;

        empresaService.buscar(id).then(empresa => {
            if(!empresa) return;
            setEmpresa(empresa)
        })
    }, [id, setEmpresa])

    async function handleSalvar() {
        let salvarEmpresa = {...empresa}

        const messages = empresaService.validar(empresa);
        if (!messages.isEmpty()) {
            setMessages(messages);
            return;
        }

        if(!!empresa.logotipo){
            salvarEmpresa.logotipo.id = (await arquivoService.salvar({...empresa.logotipo, postBack: true})).id
        }
        
        setLoading(true);

        toast.promise(
            empresaService.salvar(salvarEmpresa).then(value => {
                navigate(-1)
            }).finally(() => setLoading(false)),
            {
                "pending": `Salvando ${empresa.razaoSocial}. Aguarde...`,
                "success": `${empresa.razaoSocial} salvo com sucesso!`,
                "error": {
                    render(e) {
                        setLoading(false);
                        return `Falha ao salvar ${empresa.razaoSocial}: ${e.data?.response?.data}`;
                    }
                }
            }
        );
    }

    return(
        <FormTab
            descricao="Empresa"
            service={empresaService}
            value={empresa}
            blocked={id !== "new" && !empresa.id}
            onValidate={setMessages}
            carregando={loading}
            salvar={handleSalvar}
            podeDeletar={!!roles.EMPD}
            podeSalvar={!!roles.EMPC}
        >
            <TabPanel header="Dados Principais" leftIcon='fa-solid fa-database'>
                <PanelContent>
                    <EditarDadosPrincipais empresa={empresa} setEmpresa={setEmpresa} messages={messages} setMessages={setMessages}/>
                </PanelContent>
            </TabPanel>
        </FormTab>
    );
}
