import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {InputText} from "../../components/inputtext";

export function Observacao({message, onModalClose}) {

	const [visible, setVisible] = useState(true);
	const [observacao, setObservacao] = useState("");

	async function handleClose() {
		if (onModalClose) {
			await onModalClose(observacao);
		}
		setVisible(false);
	}

	return (
		<Dialog visible={visible} style={{width: "550px"}} header="Observações" closable={false}>
			<PanelContent>
				<InputText label={message} multiline style={{height: "8em"}} value={observacao} onChange={e => setObservacao(e.value)}/>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-save" success label="OK" onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}
