import axios from 'axios';

let acessToken = localStorage.getItem("X-Auth-Credentials") || "";
const apiUrl = process.env.REACT_APP_API_HOST;
export const api = axios.create({
    baseURL: apiUrl,
    headers: {
        Authorization: acessToken,
    }
})

export const viaCEP = axios.create({
    baseURL: 'http://viacep.com.br/ws/'
});

export const apiAnonymous = axios.create({
    baseURL: apiUrl
});
