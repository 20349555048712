import React from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";

export const EmpresaTable = React.memo(({value}) => {

    const navigate = useNavigate();
    const {roles} = useAuth()
    const handleEditar = (event) => {
        if(!roles.EMPC) return;

        toast(`Empresa ${event.data.nomeFantasia} foi selecionada.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            progressStyle: {
            }

        });
        navigate(`/empresas/${event.data.id}`)
    }

    const templateStatus = (data) => {
        if(data.status === 'ATIVO'){
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Ativado" className="fa-solid fa-city fa-lg	" style={{color:'#00940a'}}/>
                </div>
            )
        }else{
            return (
                <div style={{display: 'flex', alignItems: "center", justifyContent:"center"}}>
                    <i title="Desativado" className="fa-solid fa-city fa-lg	" style={{color:'#d10404'}}/>
                </div>
            )
        }
    }

    return (
        <DataTable
            value={value}
            onRowDoubleClick={handleEditar}
            valuelength={value?.length}
        >
            <Column style={{ width: '2em' }} sortable header="Status" body={e => templateStatus(e)}/>
            <Column sortable field="razaoSocial" header="Razão Social"  style={{ width: '*' }} />
            <Column sortable field="nomeFantasia" header="Nome Fantasia"  style={{ width: '*' }} />
            <Column sortable field="cnpjFormatado" header="CNPJ"  style={{ width: '*' }} />
        </DataTable>
    );

});
