import React, {useCallback, useState} from 'react';

import {Column} from 'primereact/column';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import moment from 'moment';
import saveAs from "file-saver";

import {Button} from '../../components/button';
import {DataTable} from '../../components/datatable';
import {withDialog} from "../../utils/dialogContext";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {isStacked} from '../../context/BreakpointContext';
import {InputText} from '../../components/inputtext';
import {SimpleFileUpload} from '../../components/fileupload/SimpleFileUpload';
import {arquivoService} from '../../service/arquivoService';
import {contratoService} from '../../service/contratoService';
import {isRequired} from '../../utils/fieldValidator';

export const EditarAnexo = withDialog(({contrato, setContrato, messages, setMessages, showDialog}) => {
	const [blocked, setBlocked] = useState(0);
	const [comprovantesSelecionados, setComprovantesSelecionados] = useState([]);


	const  validarItem = useCallback((e, index, data) =>  {
        let erro;
        let anexos = [];
        
		switch (e?.target.name) {
            case 'descricao':
                erro = isRequired(data.descricao)
                anexos = [...messages.anexos]
                anexos[index] = {...messages.anexos[index], descricao: erro}
                break;
            case 'arquivo':
				if(data.arquivo){
				}
                erro = isRequired(data.arquivo)
                anexos = [...messages.anexos]
                anexos[index] = {...messages.anexos[index], arquivo: erro}
                break;
            default:
                break;
        }

        setMessages((messages) => {
            return {
                ...messages,
                anexos
            }
        })
	}, [messages.anexos, setMessages])
	
	function adicionarComprovante() {

		setContrato({...contrato, anexos: [...contrato.anexos, contratoService.criarAnexo()]})
	}

	function removerComprovantes() {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir os comprovantes selecionados?" onYes={() => {
			const anexos = contrato.anexos.filter(osi => !comprovantesSelecionados.some(i => (i._key && osi._key === i._key) || (i.id && osi.id === i.id)));
			setContrato({...contrato, anexos})
			setComprovantesSelecionados([]);
		}}/>);
	}

	const controlSet = (
		<div>
			<Button icon="pi pi-plus" onClick={adicionarComprovante}/>
			<Button disabled={!comprovantesSelecionados?.length} onClick={removerComprovantes} danger icon="pi pi-minus"/>
		</div>
	);

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} header={controlSet}/>
			</Row>
			<Row>
				<Column selectionMode="multiple" headerStyle={{width: "3em"}}/>
				<Column style={{width: "13em"}} header="Registro"/>
				<Column header="Descrição"/>
				<Column header="Arquivo"/>
				<Column header="Download" style={{width: "5em", textAlign: "center"}}/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={5} footer={controlSet}/>
			</Row>
		</ColumnGroup>
	);

	function handleChangeComprovante(event) {
		const  anexos = contrato.anexos.map((acompanhamento, index) => {
            if (event.index === index) {
                return {...acompanhamento,  [event.name]: event.value};
            } else {
                return acompanhamento;
            }
        });
        setContrato({...contrato, anexos})
	}

	function baixarComprovante(index) {
		setBlocked(index + 1);
		arquivoService.baixar(contrato.anexos[index].arquivo).then(data => {
			setBlocked(0);
			saveAs(new Blob([data], {type: contrato.anexos[index].arquivo.contentType}), contrato.anexos[index].arquivo.fileName);
		});
	}


    return (
        <>
			<div className="col-12">
				<DataTable value={contrato.anexos}
				           headerColumnGroup={headerGroup}
				           footerColumnGroup={footerGroup}
				           selection={comprovantesSelecionados}
				           onSelectionChange={e => setComprovantesSelecionados(e.value)}
				           header={isStacked() ? null : controlSet}
				           footer={isStacked() ? null : controlSet}
				>
					<Column selectionMode="multiple"/>
					<Column field="registro" body={osic => <div style={{textAlign: "center"}}>{moment(osic.registro).format("DD/MM/YYYY HH:mm")}</div>}/>
					<Column 
						field="descricao" 
						body={(osic, c) => <InputText 
							index={c.rowIndex} 
							name="descricao" 
							value={osic.descricao} 
							onChange={handleChangeComprovante}
							onBlur={(e) => validarItem(e, c.rowIndex, osic)}
							invalid={messages.anexos?.[c.rowIndex]?.descricao}
							reduced={!messages.anexos?.[c.rowIndex]?.descricao}
						/>}
					/>
					<Column 
						body={(osic, c) => <SimpleFileUpload 
							disabled={osic.id} 
							index={c.rowIndex} 
							name="arquivo" 
							value={osic.arquivo} 
							onChange={handleChangeComprovante}
							hide={osic.id}
							invalid={messages.anexos?.[c.rowIndex]?.arquivo}
							reduced={!messages.anexos?.[c.rowIndex]?.arquivo}
						/>}
					/>
					<Column style={{textAlign: "center"}} body={(osic, c) => {
							return osic.id ? <Button loading={blocked === c.rowIndex + 1} icon="fa-solid fa-download" onClick={() => baixarComprovante(c.rowIndex)}/> : null
						}} 
					/>
				</DataTable>
			</div>
        </>
    );

});
