import React, {useMemo} from 'react';
import {TabelaContratos} from './TabelaContratos';
import {useQueryClientContrato} from "../../utils/query-clients/queryClients";
import {BlockUI} from "primereact/blockui";
import {RefetchIndicator} from "../../components/refetchindicator/RefetchIndicator";
import {headerTemplate} from "../../utils/templates";
import {PanelContent, PanelFooter} from "../../components/panel";
import {ClienteAutoComplete} from "../../components/autocomplete/ClienteAutoComplete";
import {ContratoStatusSelect} from "../../components/multiselect/contratoStatusMultselect";
import {InputNumber} from "../../components/inputnumber";
import {Calendar} from "../../components/calendar";
import {VendedorAutoComplete} from "../../components/autocomplete/VendedorAutoComplete";
import {Dropdown} from "../../components/dropdown";
import {optionsPeriodicidadeParcela, optionsVencimentoContrato} from "../../service/contratoService";
import {If} from "../../components/conditional/If";
import {Button} from "../../components/button";
import {Panel} from "primereact/panel";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useQueryParams} from "../../utils/QueryParamContext";
import {QueryParamsKeys} from "../../utils/defaultQueryParams";
import {SearchButton} from "../../components/button/SearchButton";

export function Contratos() {

	const {roles} = useAuth();
	const navigate = useNavigate();
	const {query, handleSearch} = useQueryClientContrato();
	const {params, handleChange} = useQueryParams(QueryParamsKeys.Contrato);

	function handleCriar() {
		navigate("/contratos/new");
	}

	return (
		<BlockUI blocked={query.isLoading}>
			<Panel headerTemplate={useMemo(() => headerTemplate("Contratos", "fa-file-signature"), [])}>
				<PanelContent>
					<ClienteAutoComplete col={5} value={params.cliente} name="cliente" onChange={handleChange}/>
					<ContratoStatusSelect col={4} name="status" value={params.status} onChange={handleChange}/>
					<InputNumber col={3} name='numero' label='Número' value={params.numero} onChange={handleChange} showButtons min={0} placeholder='o número do contrato.'/>
					<Calendar label="Período de Vigência" col={2} name="inicio" value={params.inicio} onChange={handleChange}/>
					<Calendar label="&nbsp;" col={2} name="fim" value={params.fim} onChange={handleChange}/>
					<VendedorAutoComplete col={4} value={params.vendedor} name="vendedor" onChange={handleChange}/>
					<Dropdown label="Periodicidade das Parcelas" col={2} options={[{label: "Todos", value: ""}, ...optionsPeriodicidadeParcela]} name="periodicidadeParcela" value={params?.periodicidadeParcela} onChange={handleChange}/>
					<Dropdown label="Vencimento em:" col={2} options={[{label: "Todos", value: ""}, ...optionsVencimentoContrato]} name="vencimento" value={params?.vencimento} onChange={handleChange}/>
				</PanelContent>
				<PanelFooter>
					<If condition={!!roles.CONA}>
						<Button success onClick={handleCriar} label="Novo" icon="pi pi-plus"/>
					</If>
					<SearchButton query={query} onSearch={handleSearch}/>
				</PanelFooter>
			</Panel>
			{useMemo(() => <TabelaContratos value={query.data || []}/>, [query.data])}
			<RefetchIndicator isRefetching={query.isRefetching}/>
		</BlockUI>
	);

}
