import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useViaCepService} from '../../service/viaCepService';
import {InputText} from '../../components/inputtext';
import {InputMask} from '../../components/inputmask';
import {isBiggerThanZero, isCEP, isCNPJ, isRequired} from '../../utils/fieldValidator';
import {ToogleInputText} from '../../components/toogleinputtext';
import {SeletorDeFoto} from '../../components/seletorDeFoto';
import { toSrc } from '../../service/fotoUtils';

export function EditarDadosPrincipais({empresa, setEmpresa, messages, setMessages}) {

    useEffect(() => {
        if (!empresa.numero) return;

        if (!isNaN(empresa.numero)) {
            setTemNumero(true)
        } else {
            setTemNumero(false)
        }
    }, [empresa.numero])

    const viaCEPservice = useViaCepService()

    //fields
    const [temNumero, setTemNumero] = useState(true)

    //functions
    const handleCEP = () => {
        messages.cep = isCEP(empresa.cep);
        setMessages({...messages});

        toast.promise(
            viaCEPservice.find(empresa.cep).then(data => {
                const atualizarInformacoesDeEndereco = {
                    ...empresa,
                    bairro: data.bairro,
                    cidade: data.localidade,
                    endereco: `${data.logradouro}${!data.complemento ? '' : ', ' + data.complemento}`,
                    uf: data.uf
                }
                setEmpresa(atualizarInformacoesDeEndereco)
            }),
            {
                pending: `Carregando informações do CEP.`,
                success: `Informações carregadas com sucesso.`,
                error: 'Erro ao tentar carregar informações do CEP informado.'
            }
        )

    }

    function handleChange(event) {
        setEmpresa({...empresa, [event.name]: event.value});
    }

    const customBase64Uploader = async (event) => {
        const file = event.target.files[0];

        if(!file) return;

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function (e) {
            const data = e.target.result;
            
            const adicionarLogotipo = {
                ...empresa,
                logotipo: {
                    ...empresa.logotipo, 
                    fileName: file.name,
                    contentType: file.type,
                    conteudo: {
                        ...empresa.logotipo?.conteudo, 
                        conteudo: data.split(",")[1]
                    }
                },

            }

            setEmpresa(adicionarLogotipo)
        }

        messages.logotipo = null;
        setMessages({...messages});
    }

    return (
        <>
            <div className='col-9 grid h-full'>
                <InputText
                    required
                    onBlur={() => {
                        messages.razaoSocial = isRequired(empresa.razaoSocial);
                        setMessages({...messages});
                    }}
                    invalid={messages.razaoSocial}
                    col={5}
                    name="razaoSocial"
                    label="Razão Social"
                    value={empresa.razaoSocial}
                    onChange={handleChange}
                />
                <InputText
                    required
                    onBlur={() => {
                        messages.nomeFantasia = isRequired(empresa.nomeFantasia);
                        setMessages({...messages});
                    }}
                    invalid={messages.nomeFantasia}
                    col={4}
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={empresa.nomeFantasia}
                    onChange={handleChange}
                />
                <InputMask
                    required
                    unmask
                    onBlur={() => {
                        messages.cnpj = isCNPJ(empresa.cnpj);
                        setMessages({...messages});
                    }}
                    invalid={messages.cnpj}
                    mask='99.999.999/9999-99'
                    col={3}
                    name="cnpj"
                    label="CNPJ"
                    value={empresa.cnpj}
                    onChange={handleChange}
                />
                <InputMask
                    required
                    unmask
                    onBlur={handleCEP}
                    invalid={messages.cep}
                    mask='99999-999'
                    col={2} name="cep"
                    label="CEP"
                    value={empresa.cep}
                    onChange={handleChange}
                />
                <InputText
                    required
                    onBlur={() => {
                        messages.endereco = isRequired(empresa.endereco);
                        setMessages({...messages});
                    }}
                    invalid={messages.endereco}
                    col={5}
                    readOnly
                    name="endereco"
                    label="Endereço"
                    value={empresa.endereco}
                />
                <ToogleInputText
                    checked={temNumero}
                    onChangeToggle={(e) => {
                        if (!e.value) {
                            const atualizarNumero = {
                                ...empresa,
                                numero: 'NAO TEM NUMERO'
                            }
                            messages.numero = null
                            setEmpresa(atualizarNumero)
                        }
                        setTemNumero(e.value)
                    }}
                    col={2}
                    onChange={handleChange}
                    disabled={!temNumero}
                    name="numero"
                    label="Número"
                    value={empresa.numero === "NAO TEM NUMERO" ? "" : empresa.numero}
                    required={temNumero}
                    type='number'
                    onBlur={() => {
                        if (!temNumero) return;
                        messages.numero = isBiggerThanZero(empresa.numero);
                        setMessages({...messages});
                    }}
                    invalid={messages.numero}

                />
                <InputText
                    required
                    onBlur={() => {
                        messages.complemento = isRequired(empresa.complemento);
                        setMessages({...messages});
                    }}
                    invalid={messages.complemento}
                    col={3} name="complemento"
                    label="Complemento"
                    value={empresa.complemento}
                    onChange={handleChange}
                />
                <InputText
                    readOnly
                    col={5} name="bairro"
                    label="Bairro"
                    value={empresa.bairro}
                />
                <InputText
                    readOnly
                    col={3}
                    name="cidade"
                    label="Cidade"
                    value={empresa.cidade}
                />
                <InputText
                    readOnly
                    col={2}
                    name="uf"
                    label="UF"
                    value={empresa.uf}
                />
                <InputMask
                    col={2}
                    name="telefone"
                    label="Telefone"
                    value={empresa.telefone}
                    keyfilter="int"
                    unmask
                    mask={'(99) 99999-999?9' }
                    onChange={handleChange}

                />
                <InputText
                    col={6}
                    name='incricaoEstadual'
                    label='Inscrição Estadual'
                    value={empresa.incricaoEstadual}
                    onChange={handleChange}
                />
                <InputText
                    col={6}
                    name='email'
                    label='Email'
                    value={empresa.email}
                    type={'email'}
                    onChange={handleChange}
                />
            </div>
            <SeletorDeFoto
                uploader={customBase64Uploader}
                value={toSrc(empresa.logotipo)}
                key="logotipo"
                required
                invalid={messages.logotipo}
            />
        </>
    )
}
