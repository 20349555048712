import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {Button} from "../../components/button";
import {api} from "../../service/api";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";

export const TabelaFluxo = React.memo(({value}) => {

    const {roles} = useAuth();
    const navigate = useNavigate();
    const {showDialog} = useContext(DialogContext);

    function handleEditar(event) {
        navigate(`/ordens-servico/fluxos/${event.data.id}`);
    }

    function unificarVersoes() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja unificar as versões de todos os fluxos?" onYes={() => {
            api.get("/ordens-servico/fluxos/unificar-versoes");
        }}/>);
    }

    function atualizarRestricoes() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja atualizar as restrições de todas as ordens de serviço?" onYes={() => {
            api.get("/ordens-servico/aux/restricoes");
        }}/>);
    }

    function atualizarCores() {
        showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja atualizar as cores de todas as ordens de serviço?" onYes={() => {
            api.get("/ordens-servico/aux/cores");
        }}/>);
    }

    return (
        <DataTable header={(
            <div>
                {!!roles.OSED ? <Button title="Unificar Versões" danger icon="fas fa-arrows-to-circle" onClick={unificarVersoes}/> : null}
                {!!roles.OSED ? <Button title="Atualizar Restrições" danger icon="fas fa-exclamation-triangle" onClick={atualizarRestricoes}/> : null}
                {!!roles.OSED ? <Button title="Atualizar Cores" danger icon="fas fa-palette" onClick={atualizarCores}/> : null}
            </div>
        )} value={value} onRowDoubleClick={handleEditar} valuelength={value?.length}>
            <Column sortable header="Descrição" field="descricao"/>
        </DataTable>
    );

});
