export function byUltimaAlteracao(os1, os2) {
    if (os1.ultimaAlteracao) {
        if (os2.ultimaAlteracao) {
            return os2.ultimaAlteracao.localeCompare(os1.ultimaAlteracao);
        } else {
            return -1;
        }
    } else if (os2.ultimaAlteracao) {
        return 1;
    }
    return 0;
}

export function byCodigo(a, b) {
    return a?.codigo - b?.codigo;
}

export function bySequencia(a, b) {
    return a?.sequencia - b?.sequencia;
}

export function bySaldoRecondicionadas(a, b) {
    return a?.saldoRecondicionadas - b?.saldoRecondicionadas;
}

export function bySaldoRecondicionadasDesc(a, b) {
    return b?.saldoRecondicionadas - a?.saldoRecondicionadas;
}
