import {ConfirmDialogV2} from "../../components/confirmdialog";
import {Foto} from "../../components/foto";
import {toSrc} from "../../service/fotoUtils";
import React from "react";
import {PanelContent} from "../../components/panel";
import {withDialog} from "../../utils/dialogContext";

export const EditarOrdemServicoItemFoto = withDialog(({item, handleChange, showDialog, readOnly}) => {

	function handleFoto(event) {
		item.fotos.push({
			fileName: event.conteudo.fileName,
			contentType: event.conteudo.contentType,
			conteudo: {
				conteudo: event.conteudo.content
			}
		});
		handleChange({name: "fotos", value: item.fotos});
	}

	function excluirFoto(index) {
		showDialog(<ConfirmDialogV2 message="Tem certeza de que deseja excluir esta foto?" onYes={() => {
			item.fotos.splice(index, 1);
			handleChange({name: "fotos", value: item.fotos});
		}}/>);
	}

	function renderFoto(f, index) {
		return (
			<div className="md:col-4 sm:col-12 xm">
				<Foto contentType={f.contentType} thumb accept="image/jpeg, image/png, image/bmp, video/mp4, video/webm, video/mpeg, video/x-ms-wmv, video/x-msvideo, video/x-ms-asf" readOnly={item.fotos[index]?.id} value={toSrc(item.fotos[index])} onChange={conteudo => handleFoto({conteudo, index})} onDelete={() => excluirFoto(index)}/>
			</div>
		);
	}

	let listFotos = [...item.fotos];

	if (!readOnly) {
		listFotos.push({});
	}

	return (
		<PanelContent>
			{listFotos.map(renderFoto)}
		</PanelContent>
	);

});
