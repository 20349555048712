import moment from 'moment/moment';
import {
    buildValidator,
    isBiggerThanZero,
    isBiggerThanZeroValor,
    isEntityRequired,
    isRequired
} from '../utils/fieldValidator';
import {statusPadrao} from '../utils/statusPadrao';
import {api} from './api';
import {baseService} from './baseService';
import { arquivoService } from './arquivoService';
import {toQueryString} from "../utils/stringUtils";

const resourceUrl = "/contratos";

export const contratoService = {
    criar: () => {
        return {
            id: null,
            acompanhamentos:[],
            clausulas:[],
            clientes: [],
            modelos: [],
            equipamentos: [],
            produtos: [],
            servicos: [],
            anexos: [],
            objeto: "",
            contratoAnterior: null,
            inicio: null,
            fim: null,
            status: 'PENDENTE',
            tipoContrato: null,
            valor: null,
            diaVencimento: 1,
            acordoNivelServico: null,
            quantidadeParcelas: null,
            quantidadeEquipamentos: 0,
            emissao: null,
            projetoItens: [],
            contratoParametros: {
                cobeturaMO: false,
                cobeturaMODescricao: null,
                localidade: false,
                localidadeDescricao: null,
                treinamentoOperacional: false,
                treinamentoOperacionalDescricao: null,
                condenacaoDoEquipamento: false,
                condenacaoDoEquipamentoDescricao: null,
                suporte: false,
                suporteDescricao: null,
                logisticaZecode: false,
                logisticaZecodeDescricao: null,
                slaLaboratorio: false,
                slaLaboratorioDescricao: null,
                descontoPeca: false,
                descontoPecaDescricao: null,
                preventiva: false,
                preventivaDescricao: null,
                backupCliente: false,
                backupClienteDescricao: null,
                backupContrato: false,
                backupContratoDescricao: null,
                recuperacoModulos: false,
                recuperacoModulosDescricao: null,
                baseline: false,
                baselineDescricao: null,
                mauUso: false,
                mauUsoDescricao: null,
                multa: false,
                multaDescricao: null,
                reajuste: false,
                reajusteDescricao: null,
                indiceReajuste: false,
                indiceReajusteDescricao: null,
                coberturaPecas: null,
                manutencaoCorretiva: false,
                manutencaoCorretivaDescricao: null,
            }
        };
    },
    criarItem: () => {
        return{
            id: null,
            _key: Math.random(),
            modoPeriodicidade: "MESES",
            produto: null,
            equipamento: null,
            servico: null,
            periodicidade: 1,
            quantidade: 0,
            quantidadeMauUso: 0,
            quantidadeEquipamento: 0,
            valorCobertura: null,
            valorExcedente: null
        };
    },
    criarContratoEquipamento: () => {
		return {
			id: null,
			descricao: "",
			serial: "",
			produto: null,
			cliente: null,
            _key: Math.random() * 1000
		};
	},
    criarContratoAcompanhamento: (acompanhamentos) => {
        return {
            id: null,
            acompanhamento: '',
            data: moment().format('YYYY-MM-DDTHH:mm:ss'),
            responsavel: null,
            atribuidor: null,
            item: acompanhamentos.length +1,
            _key: Math.random() * 1000
        }
    },
    criarAnexo() {
		return {
			id: null,
			_key: Math.random(),
			descricao: "",
			arquivo: null
		};
	},
    validar: (contrato) => {
        const messages = buildValidator();
        //DADOS PRINCIPAIS
        messages.cliente = isRequired(contrato.cliente?.id);
        messages.inicio = isRequired(contrato.inicio);
        messages.fim = isRequired(contrato.fim);
        messages.tipoContrato = isEntityRequired(contrato.tipoContrato)
        
        //PARAMETROS
        if (contrato.contratoParametros?.cobeturaMO) {
            messages.cobeturaMO = isRequired(contrato.contratoParametros?.cobeturaMODescricao)
        }
        if (contrato.contratoParametros?.localidade) {
            messages.localidade = isRequired(contrato.contratoParametros?.localidadeDescricao)
        }
        if (contrato.contratoParametros?.treinamentoOperacional) {
            messages.treinamentoOperacional = isRequired(contrato.contratoParametros?.treinamentoOperacionalDescricao)
        }
        if (contrato.contratoParametros?.condenacaoDoEquipamento) {
            messages.condenacaoDoEquipamento = isRequired(contrato.contratoParametros?.condenacaoDoEquipamentoDescricao)
        }
        if (contrato.contratoParametros?.suporte) {
            messages.suporte = isRequired(contrato.contratoParametros?.suporteDescricao)
        }
        if (contrato.contratoParametros?.logisticaZecode) {
            messages.logisticaZecode = isRequired(contrato.contratoParametros?.logisticaZecodeDescricao)
        }
        if (contrato.contratoParametros?.slaLaboratorio) {
            messages.slaLaboratorio = isRequired(contrato.contratoParametros?.slaLaboratorioDescricao)
        }
        if (contrato.contratoParametros?.descontoPeca) {
            messages.descontoPeca = isBiggerThanZeroValor(contrato.contratoParametros?.descontoPecaDescricao)
        }
        if (contrato.contratoParametros?.preventiva) {
            messages.preventiva = isRequired(contrato.contratoParametros?.preventivaDescricao)
        }
        if (contrato.contratoParametros?.backupCliente) {
            messages.backupCliente = isRequired(contrato.contratoParametros?.backupClienteDescricao)
        }
        if (contrato.contratoParametros?.backupContrato) {
            messages.backupContrato = isRequired(contrato.contratoParametros?.backupContratoDescricao)
        }
        if (contrato.contratoParametros?.recuperacoModulos) {
            messages.recuperacoModulos = isRequired(contrato.contratoParametros?.recuperacoModulosDescricao)
        }
        if (contrato.contratoParametros?.baseline) {
            messages.baseline = isRequired(contrato.contratoParametros?.baselineDescricao)
        }
        if (contrato.contratoParametros?.mauUso) {
            messages.mauUso = isRequired(contrato.contratoParametros?.mauUsoDescricao)
        }
        if (contrato.contratoParametros?.manutencaoCorretiva) {
            messages.manutencaoCorretiva = isRequired(contrato.contratoParametros?.manutencaoCorretivaDescricao)
        }

        //clausulas
        if (!messages.clausulas) {
			messages.clausulas = [...Array(contrato.clausulas.length)].map((_, i) => ({
				tipo: null,
				texto: null,
			}));
		}

		for (let i = 0; i < contrato.clausulas.length; ++i) {
			messages.clausulas[i].tipo = isEntityRequired(contrato.clausulas[i].tipo);
			messages.clausulas[i].texto = isRequired(contrato.clausulas[i].texto)
		}

        // Coberturas
        for (const tipo of ["produtos", "servicos", "equipamentos"]) {
            if(tipo === 'produtos'){
                messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
                    periodicidade: contrato[tipo][i]?.modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i]?.periodicidade, "Obrigatório"),
                    modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
                    quantidade: tipo === "equipamentos" ? null : isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
                    valorCobertura: tipo === "equipamentos" || contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
                    produto: isEntityRequired(contrato[tipo][i].produto),
                }));
            }
            if(tipo === 'servicos'){
                messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
                    periodicidade: contrato[tipo][i]?.modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i]?.periodicidade, "Obrigatório"),
                    modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
                    quantidade: tipo === "equipamentos" ? null : isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
                    valorCobertura: tipo === "equipamentos" || contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
                    servico: isEntityRequired(contrato[tipo][i].servico),
                }));
            }
            
            if(tipo === 'equipamentos'){
                console.log('entrou aqui')
                messages[tipo] = [...Array(contrato[tipo].length)].map((_, i) => ({
                    periodicidade: contrato[tipo][i]?.modoPeriodicidade === "CONTRATO" ? null : isBiggerThanZero(contrato[tipo][i]?.periodicidade, "Obrigatório"),
                    modoPeriodicidade: isRequired(contrato[tipo][i].modoPeriodicidade),
                    quantidade: tipo === "equipamentos" ? null : isBiggerThanZero(contrato[tipo][i].quantidade, "Obrigatório"),
                    valorCobertura: tipo === "equipamentos" || contrato[tipo][i].valorCobertura != null ? null : "Obrigatório",
                    equipamento: isEntityRequired(contrato[tipo][i].equipamento),
                }));
            }
        }
        
        //acompanhamentos
        if (!messages.acompanhamentos) {
			messages.acompanhamentos = [...Array(contrato.acompanhamentos.length)].map((_, i) => ({
				responsavel: null,
				acompanhamento: null,
			}));
		}

		for (let i = 0; i < contrato.acompanhamentos.length; ++i) {
			messages.acompanhamentos[i].responsavel = isEntityRequired(contrato.acompanhamentos[i].responsavel);
			messages.acompanhamentos[i].acompanhamento = isRequired(contrato.acompanhamentos[i].acompanhamento)
		}

        //FINANCEIRO
        messages.valor = isBiggerThanZeroValor(contrato.valor)
        messages.diaVencimento = isBiggerThanZero(contrato.diaVencimento)
        messages.periodicidadeParcela = isRequired(contrato.periodicidadeParcela)
        messages.emissao = isRequired(contrato.emissao)
        messages.condicaoPagamento = isRequired(contrato.condicaoPagamento?.id);
        if (contrato.contratoParametros?.multa) {
            messages.multa = isRequired(contrato.contratoParametros?.multaDescricao)
        }
        if(contrato.contratoParametros?.reajuste){
            messages.reajuste = isRequired(contrato.contratoParametros?.reajusteDescricao)
        }
        if(contrato.contratoParametros?.indiceReajuste){
            messages.indiceReajuste = isRequired(contrato.contratoParametros?.indiceReajusteDescricao)
        }
        
        //ANEXOS
        if (!messages.anexos) {
			messages.anexos = [...Array(contrato.anexos.length)].map((_, i) => ({
				descricao: null,
				arquivo: null,
			}));
		}

		for (let i = 0; i < contrato.anexos.length; ++i) {
			messages.anexos[i].descricao = isRequired(contrato.anexos[i].descricao);
            if(!contrato.anexos[i]?.arquivo?.id){
                messages.anexos[i].arquivo = isRequired(contrato.anexos[i].arquivo?.conteudo?.conteudo)
            }
		}

        return messages;
    },

    salvarDependencias: async (contrato) => {
        for (const anexo of contrato.anexos) {
			if (!anexo.id) {
				anexo.arquivo = await arquivoService.salvar({...anexo.arquivo, postBack: true});
			}
		}

		return await api.post(resourceUrl, contrato).then(response => response.data);
    },

    validarAbas: (contrato) => {
        const messages = buildValidator();
        //DADOS PINCIPAIS
        const alertasDadosPrincipais = [];
        alertasDadosPrincipais.push(isRequired(contrato.cliente?.id, 'Preencher campo cliente.'))
        alertasDadosPrincipais.push(isRequired(contrato.inicio, 'Selecionar campo inicio.'))
        alertasDadosPrincipais.push(isRequired(contrato.fim, 'Selecionar campo fim.'))
        alertasDadosPrincipais.push(isEntityRequired(contrato.tipoContrato, 'Selecionar campo tipo de contrato.'))
        if(alertasDadosPrincipais.some(element => element)){
            messages.dadosPrincipais = alertasDadosPrincipais
        }
        //PARAMETROS
        const alertaParametros = [];
        if(contrato.contratoParametros?.cobeturaMO){
            alertaParametros.push(isRequired(contrato.contratoParametros?.cobeturaMODescricao, "Detalhar a cobertura de MO."))
        }
        if(contrato.contratoParametros?.localidade){
            alertaParametros.push(isRequired(contrato.contratoParametros?.localidadeDescricao, "Detalhar a localidade."))
        }
        if(contrato.contratoParametros?.treinamentoOperacional){
            alertaParametros.push(isRequired(contrato.contratoParametros?.treinamentoOperacionalDescricao, "Detalhar o treinamento operacional."))
        }
        if(contrato.contratoParametros?.condenacaoDoEquipamento){
            alertaParametros.push(isRequired(contrato.contratoParametros?.condenacaoDoEquipamentoDescricao, "Detalhar a condenação de equipamentos."))
        }
        if(contrato.contratoParametros?.suporte){
            alertaParametros.push(isRequired(contrato.contratoParametros?.suporteDescricao, "Detalhar o suporte."))
        }
        if(contrato.contratoParametros?.logisticaZecode){
            alertaParametros.push(isRequired(contrato.contratoParametros?.logisticaZecodeDescricao, "Detalhar a logistica da Zecode."))
        }
        if(contrato.contratoParametros?.slaLaboratorio){
            alertaParametros.push(isRequired(contrato.contratoParametros?.slaLaboratorioDescricao, "Detalhar o tempo da SLA em laboratório."))
        }
        if(contrato.contratoParametros?.descontoPeca){
            alertaParametros.push(isBiggerThanZero(contrato.contratoParametros?.descontoPecaDescricao, "Detalhar o desconto em peças."))
        }
        if(contrato.contratoParametros?.preventiva){
            alertaParametros.push(isRequired(contrato.contratoParametros?.preventivaDescricao, "Detalhar a preventiva."))
        }
        if(contrato.contratoParametros?.backupCliente){
            alertaParametros.push(isRequired(contrato.contratoParametros?.backupClienteDescricao, "Detalhar o backup com o cliente."))
        }
        if(contrato.contratoParametros?.backupContrato){
            alertaParametros.push(isRequired(contrato.contratoParametros?.backupContratoDescricao, "Detalhar a backup em contrato."))
        }
        if(contrato.contratoParametros?.recuperacoModulos){
            alertaParametros.push(isRequired(contrato.contratoParametros?.recuperacoModulosDescricao, "Detalhar a recuperação de modulos"))
        }
        if(contrato.contratoParametros?.baseline){
            alertaParametros.push(isRequired(contrato.contratoParametros?.baselineDescricao, "Detalhar a baseline."))
        }
        if(contrato.contratoParametros?.mauUso){
            alertaParametros.push(isRequired(contrato.contratoParametros?.mauUsoDescricao, "Detalhar a cobertura de mau uso."))
        }
        if(contrato.contratoParametros?.manutencaoCorretiva){
            alertaParametros.push(isRequired(contrato.contratoParametros?.manutencaoCorretivaDescricao, "Detalhar a manutencão corretiva."))
        }
        if(alertaParametros.some(element => element)){
            messages.contratoParametros = alertaParametros;
        }
        //CLAUSULAS
        if(!!contrato.clausulas[0]){
            const alertasClausulas = [];
            // eslint-disable-next-line no-unused-expressions
            contrato?.clausulas?.forEach(clausula => {
                if(clausula?.texto?.length <= 0){
                    alertasClausulas.push(`Preencha o campo texto na clausula de item ${clausula.item + 1}`)
                }
                if(!clausula?.tipo?.id){
                    alertasClausulas.push(`Preencha o campo tipo na clausulas de item ${clausula.item + 1}`)
                }
            });
            if(alertasClausulas.some(element => element)){
                messages.clausulas = alertasClausulas;
            }
        }
        //ACOMPANHAMENTOS
        if(!!contrato.acompanhamentos[0]){
            const alertaAcompanhamentos = []
            // eslint-disable-next-line no-unused-expressions
            contrato?.acompanhamentos?.forEach((acompanhamento) => {
                alertaAcompanhamentos.push(isEntityRequired(acompanhamento.responsavel, `Preencha o campo responsável do acompanhamento de item ${acompanhamento.item}`))
                alertaAcompanhamentos.push(isRequired(acompanhamento.acompanhamento, `Detalhe o campo acompanhamento no acompanhamento de item ${acompanhamento.item}`))
            });
            if(alertaAcompanhamentos.some(element => element)){
                messages.acompanhamentos = alertaAcompanhamentos;
            }
        }
        //FINANCEIRO
        const alertaFinanceiros = [];
        alertaFinanceiros.push(isBiggerThanZeroValor(contrato.valor, 'Informe o valor que será cobrado por parcela.'))
        alertaFinanceiros.push(isBiggerThanZero(contrato.quantidadeParcelas, 'Informe a quantidade de parcelas.'))
        alertaFinanceiros.push(isBiggerThanZero(contrato.diaVencimento, 'Informe o dia do mês que a cobrança da parcela ocorrerá.'))
        alertaFinanceiros.push(isRequired(contrato.periodicidadeParcela, 'Informe periodicidade das parcelas.'))
        alertaFinanceiros.push(isRequired(contrato.emissao, 'Informe o tipo de emissão.'))
        alertaFinanceiros.push(isEntityRequired(contrato.condicaoPagamento, "Informe a condição de pagamento"));

        if(contrato.contratoParametros?.multa){
            alertaFinanceiros.push(isRequired(contrato.contratoParametros?.multaDescricao, "Detalhar o valor da multa."))
        }
        if(contrato.contratoParametros?.reajuste){
            alertaFinanceiros.push(isRequired(contrato.contratoParametros?.reajusteDescricao, "Detalhar a reajuste do contrato."))
        }
        if(contrato.contratoParametros?.indiceReajuste){
            alertaFinanceiros.push(isRequired(contrato.contratoParametros?.indiceReajusteDescricao, "Detalhar o índice de reajudes do contrato."))
        }
        if(alertaFinanceiros.some(element => element)){
            messages.financeiro = alertaFinanceiros
        }

        return messages;
    },
    renovar: async (contrato) => {
		return await api.post(`${resourceUrl}/renovar`, contrato).then(response =>{ 
			return response.data
		});
    },
    async listarContratosAtivos(cliente, serial, codigo) {
        const params = {serial, codigo};
        const query = Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join("&");
        return await api.get(resourceUrl + `/ativos/${cliente}?${query}`).then(response => response.data);
    },
    async listarProjetosAtivos(cliente) {
        return await api.get(resourceUrl + `/projetos/ativos?cliente=${cliente}`).then(res => res.data);
    },
    async listarEquipamentosPorContrato(id, cliente) {
        return await api.get(resourceUrl + `/equipamentos/${id}${cliente ? `?cliente=${cliente}` : ""}`).then(response => response.data);
    },
    ...baseService(resourceUrl),
    async buscarPeriodoConsumo(criterio) {
        return await api.post(resourceUrl + "/franquia", criterio).then(response => response.data);
    },
    async buscarOrdensPeriodo(id) {
        return await api.get(resourceUrl + `/franquia/periodo/${id}`).then(response => response.data);
    },
    async listarConsumoPeriodos(id) {
        return await api.get(resourceUrl + `/franquia/consumo/${id}`).then(response => response.data);
    },
    async listarContratosCobertura(criterio) {
        return await api.post(resourceUrl + "/cobertura", criterio).then(response => response.data);
    },
    async adicionarEquipamento(contrato, equipamento) {
        return await api.post(resourceUrl + `/cobertura/equipamento?contrato=${contrato}&equipamento=${equipamento}`).then(response => response.data);
    },
    async listarProjetos(params) {
        return await api.get(resourceUrl + `/projetos?${toQueryString(params)}`).then(res => res.data);
    },
    async listarEquipamentosAtendidos(projeto) {
        return await api.get(resourceUrl + `/projetos/equipamentos?projeto=${projeto}`).then(res => res.data);
    },
    async listarEquipamentosAtendidosCsv(projeto) {
        return await api.get(resourceUrl + `/projetos/equipamentos/csv?projeto=${projeto}`).then(res => res.data);
    },
    async listarContratos(params) {
        const query = [];
        if (params.cliente?.id) query.push(`cliente=${params.cliente.id}`);
        if (params.numero) query.push(`numero=${params.numero}`);
        if (params.status?.length) query.push(`status=${params.status}`);
        if (params.inicio?.length) query.push(`inicio=${params.inicio}`);
        if (params.fim?.length) query.push(`fim=${params.fim}`);
        if (params.vendedor?.id) query.push(`vendedor=${params.vendedor.id}`);
        if (params.periodicidadeParcela?.length) query.push(`periodicidadeParcela=${params.periodicidadeParcela}`);
        if (params.vencimento === 0 || params.vencimento) query.push(`vencimento=${params.vencimento}`);
        return await contratoService.listar(query);
    },
    async buscarAtualizacaoValores(atualizacaoValores) {
        return await api.post("/contratos/atualizar-valores", atualizacaoValores).then(res => res.data);
    },
    async recalcularConsumos(contrato) {
        return await api.post(`/contratos/franquia/recalcular-consumos/${contrato.id}`);
    }
};

export const status = [
	{label: "Pendente", value: "PENDENTE", ...statusPadrao.warning},
	{label: "Ativo", value: "ATIVO", ...statusPadrao.sucesso},
	{label: "Cancelado", value: "CANCELADO", ...statusPadrao.cancelado},
	{label: "Renovado", value: "RENOVADO", ...statusPadrao.renovado},
	{label: "Encerrado", value: "ENCERRADO", color: statusPadrao.cancelado.color, icon: 'fa-file-circle-xmark'}
];

export const tipoDeContrato = [ 
    {label: 'Comodato' , value: 'COMODATO'},
    {label: 'Locação' , value: 'LOCACAO'},
    {label: 'Manutenção' , value: 'MANUTENCAO'},
    {label: 'Suporte' , value: 'SUPORTE'},
    {label: 'Outsourcing' , value: 'OUTSOURCING'},
];

export const optionsModoPeriodicidade = [
    {label: "Contrato", value: "CONTRATO"},
    {label: "Dias", value: "DIAS"},
    {label: "Meses", value: "MESES"}
];


export const optionsPeriodicidadeParcela = [
    {label: "Mensal", value: "MENSAL"},
    {label: "Bimestral", value: "BIMESTRAL"},
    {label: "Trimestral", value: "TRIMESTRAL"},
    {label: "Semestral", value: "SEMESTRAL"},
    {label: "Anual", value: "ANUAL"},
    {label: "Única", value: "UNICA"},
];
export const optionsVencimentoContrato = [
    {label: "90 dias", value:  90},
    {label: "60 dias", value:  60},
    {label: "30 dias", value:  30},
    {label: "Vencidos", value:  0},
];

export const optionsEmissao = [
    {label: "CR - Nota de Débito", value: "CR"},
    {label: "VE - Nota Fiscal", value: "VE"},
];
