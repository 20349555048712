import {useAuth} from "../../context/AuthContext";
import React, {useContext, useState} from "react";
import {DialogContext} from "../../utils/dialogContext";
import {nextStatusLaboratorio, optionsStatusLaboratorio, ordemServicoService} from "../../service/ordemServicoService";
import {ProgressDialog} from "../../components/progressdialog/ProgressDialog";
import {Dialog, DialogFooter} from "../../components/dialog";
import {PanelContent} from "../../components/panel";
import {Button} from "../../components/button";
import {Dropdown} from "../../components/dropdown";
import {InputText} from "../../components/inputtext";
import {If} from "../../components/conditional/If";
import {Observacao} from "./Observacao";
import {ConfirmDialogV2} from "../../components/confirmdialog";

export function RetrocederEtapa({status, ordensServico, onModalClose}) {

	const {showDialog} = useContext(DialogContext);
	const [visible, setVisible] = useState(true);
	const statusAnteriores = optionsStatusLaboratorio.slice(optionsStatusLaboratorio.findIndex(s => s.value === status) + 1);
	const [params, setParams] = useState({
		status: statusAnteriores[0].value,
		observacoes: null
	});

	function handleChange(event) {
		setParams(prevParams => ({...prevParams, [event.name]: event.value}));
	}

	async function handleSalvar() {
		if (params.observacoes?.length) {
			params.observacoes += " - Ordem de serviço retrocedida";
		} else {
			params.observacoes = "Ordem de serviço retrocedida";
		}
		showDialog(<ConfirmDialogV2 red message="Tem certeza de que deseja retroceder esta ordem de serviço para a etapa anterior?" onYes={() => {
			showDialog(<ProgressDialog onProgress={async onProgress => {
				let progress = 0;
				for (const ordemServico of ordensServico) {
					await ordemServicoService.retroceder(ordemServico, params);
					onProgress(++progress / ordensServico.length);
				}
				if (onModalClose) {
					await onModalClose();
				}
				handleClose();
			}}/>);
		}}/>);
	}

	function handleClose() {
		setVisible(false);
	}

	return (
		<Dialog onHide={handleClose} visible={visible} style={{width: "550px"}} header="Retroceder Ordem de Serviço">
			<PanelContent>
				<Dropdown label="Para qual status deseja voltar a ordem de serviço?" options={statusAnteriores} name="status" value={params.status} onChange={handleChange}/>
				<InputText multiline style={{height: "8em"}} col={12} label="Observações" name="observacoes" value={params.observacoes} onChange={handleChange}/>
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-save" success label="Confirmar" onClick={handleSalvar}/>
				<Button icon="fas fa-times" secondary label="Cancelar" onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);

}

export function AvancarEtapa({status, ordensServico, onModalClose}) {

	const {usuario} = useAuth();
	const {showDialog} = useContext(DialogContext);
	const [visible, setVisible] = useState(true);

	function handleClose() {
		setVisible(false);
	}

	async function handleYes() {
		showDialog(<Observacao message="Adicione algum comentário e/ou clique em OK" onModalClose={async observacoes => {
			const acompanhamento = {
				...ordemServicoService.criarAcompanhamento(),
				etapa: await ordemServicoService.findEtapaLaboratorioForStatus(nextStatusLaboratorio[status]),
				atribuinte: usuario,
				observacoes
			};
			const acompanhamentoCancelada = {
				...ordemServicoService.criarAcompanhamento(),
				etapa: await ordemServicoService.findEtapaLaboratorioForStatus(nextStatusLaboratorio["TESTE"]),
				atribuinte: usuario,
				observacoes
			};
			showDialog(<ProgressDialog onProgress={async onProgress => {
				let progresso = 0;
				for (const ordemServico of ordensServico) {
					if (ordemServico.cancelada && ordemServico.status !== "EXPEDICAO") {
						await ordemServicoService.salvarAcompanhamento({...acompanhamentoCancelada, ordemServico: {id: ordemServico.id}});
					} else {
						await ordemServicoService.salvarAcompanhamento({...acompanhamento, ordemServico: {id: ordemServico.id}});
					}
					onProgress(++progresso / ordensServico.length);
				}
				if (onModalClose) {
					await onModalClose();
				}
			}}/>);
		}}/>);
		handleClose();
	}

	function handleNo() {
		showDialog(<RetrocederEtapa ordensServico={ordensServico} onModalClose={onModalClose} status={status}/>);
		handleClose();
	}

	return (
		<Dialog visible={visible} style={{width: "400px"}} onHide={handleClose} header="Confirmação">
			<PanelContent>
				{questaoConfirmacao(status)}
			</PanelContent>
			<DialogFooter>
				<Button icon="fas fa-check" success label="Sim" onClick={handleYes}/>
				<If condition={status === "RECEPCAO"} children={<Button icon="fas fa-times" secondary label="Fechar" onClick={handleClose}/>}/>
				<If condition={status !== "RECEPCAO"} children={<Button icon="fas fa-times" danger label="Não" onClick={handleNo}/>}/>
			</DialogFooter>
		</Dialog>
	);

}

function questaoConfirmacao(statusAnterior) {
	switch (statusAnterior) {
		case "RECEPCAO": return "A recepção foi concluída com sucesso?";
		case "LAUDO": return "O laudo foi concluído com sucesso?";
		case "MANUTENCAO": return "A manutenção foi concluída com sucesso?";
		case "LIMPEZA": return "A limpeza foi concluída com sucesso?";
		case "TESTE": return "O teste foi concluído com sucesso?";
		case "EXPEDICAO": return "A expedição foi concluída com sucesso?";
		default: return "A etapa foi concluída com sucesso?";
	}
}
