import React, {useMemo, useState} from "react";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {Button} from "../../components/button";
import {PanelContent} from "../../components/panel";
import {arquivoService} from "../../service/arquivoService";
import saveAs from "file-saver";
import {labelOrcamentoFormaFaturamento, labelOrcamentoStatus, ordemServicoService} from "../../service/ordemServicoService";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {BlockUI} from "primereact/blockui";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {withDialog} from "../../utils/dialogContext";
import {isStacked} from "../../context/BreakpointContext";
import {formatCurrency} from "../../utils/numberFormatter";
import { InformationDialog } from "../../components/dialog/InformationDialog";
import { OrdemServicoOrcamentoEmailReenvio } from "./OrdemServicoOrcamentoEmailReenvio";
import { DialogAprovacaoOrcamento } from "./DialogAprovacaoOrcamento";
import { useAuth } from "../../context/AuthContext";
import {Checkbox} from "../../components/checkbox";
import {contratoService} from "../../service/contratoService";

export const EditarOrdemServicoItemOrcamento = withDialog(({ordemServico, item, handleChange, showDialog, handleSalvar, index, setOrdemServico}) => {
	const [blocked, setBlocked] = useState(false);
	const [uiBlocked, setUIBlocked] = useState(false);
	const [modalOrcamentoAprovacao, setModalOrcamentoAprovacao] = useState(false);
	const [orcamentoIndex, setOrcamentoIndex] = useState(null);
	const [contratoPeriodoConsumo, setContratoPeriodoConsumo] = useState(null);
	const {roles, usuario} = useAuth();

	const isFranquia = useMemo(() => ordemServico.contrato?.valorFranquia > 0, [ordemServico.contrato]);
	const disableNew = item.orcamentos?.some(osio => osio.orcamento && ["GERADO", "ENVIADO"].includes(osio.status));

	function baixarOrcamento(index, osio) {
		setBlocked(index + 1);
		arquivoService.baixar(osio.orcamento).then(data => {
			setBlocked(0);
			saveAs(new Blob([data], {type: "application/pdf"}), `Orcamento-${osio.numero}.pdf`);
		});
	}

	const reenviarOrcamento = (index, osio) => {
		showDialog(<OrdemServicoOrcamentoEmailReenvio ordemServicoItemOrcamento={{...osio, ordemServicoNumero: ordemServico.numero}} />);
	}

	function gerarOrcamento() {
		if (!ordemServico.condicaoPagamento?.id) {
			return showDialog(<InformationDialog header="Informação" message='O campo "Condição de Pagamento" não está preenchido, por favor preencha.'/>);
		} else if (!ordemServico.clienteFaturamento?.id) {
			return showDialog(<InformationDialog header="Informação" message='O campo "Cliente Faturamento" não está preenchido, por favor preencha.'/>);
		}
		showDialog(<ConfirmDialogV2 message="Confirma a geração do orçamento para este item da ordem de serviço?" onYes={async () => {
			setUIBlocked(true);
			await handleSalvar(ordemServico);
			ordemServicoService.gerarOrcamento(ordemServico, item.id || ordemServico.itens[0].id).then(async () => {
				await ordemServicoService.buscarOrdemServico(ordemServico.id).then(ordemServico => {
					// setEtapas(ordemServico.etapas.map(e => ({label: e.descricao, value: e})));
					if (ordemServico.acompanhamentos) {
						ordemServico.acompanhamentos = ordemServico.acompanhamentos.sort((a, b) => {
							if (a.inicio !== b.inicio) {
								return a.inicio.localeCompare(b.inicio);
							}
							return a.fim ? 1 : b.fim ? -1 : 0;
						});
					}
					setOrdemServico(ordemServico);
				});
			}).finally(() => {
				setUIBlocked(false);
			});
		}}/>);
	}

	const valorPecas = item.pecas?.filter(peca => peca.status !== 'CANCELADO').map(osip => isFranquia ? osip.valorFranquia : osip.valorTotal).reduce((a, b) => a + b, 0);
	const valorServicos = item.servicos?.filter(osis => osis.status !== "MALSUCEDIDO").map(osis => isFranquia ? osis.valorFranquia : osis.valorTotal).reduce((a, b) => a + b, 0);

	const orcamentoControlSet = (
		<div>
			<Button onClick={gerarOrcamento} disabled={disableNew} style={{width: "auto", whiteSpace: "nowrap"}} label="Gerar Orçamento" icon="fa-solid fa-dollar-sign"/>
			<div style={{float: "right", fontWeight: "normal", fontStyle: "italic"}}>
				Valor Total{isFranquia ? " (Franquia)" : ""}: <b>{formatCurrency(valorPecas + valorServicos)}</b> (Peças: {formatCurrency(valorPecas)}, Serviços: {formatCurrency(valorServicos)})
			</div>
			<div style={{padding: "1em 0"}}>
				<Checkbox name="cancelada" value={ordemServico.cancelada} onChange={() => setOrdemServico({...ordemServico, cancelada: !ordemServico.cancelada})} label="Ordem de Serviço Cancelada"/>
			</div>
		</div>
	);

	const headerColumnGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={9} header={orcamentoControlSet}/>
			</Row>
			<Row>
				<Column style={{width: "7m"}} header="Nº"/>
				<Column header="Status"/>
				<Column header="Responsável"/>
				<Column header="Forma de Faturamento"/>
				<Column header="Cliente de Faturamento"/>
				<Column header="Gerado Em" style={{width: "13em"}}/>
				<Column header="Concluído Em" style={{width: "13em"}}/>
				<Column header="Aprovado/Reprovado por" style={{width: "13em"}}/>
				<Column header="Ações"/>
			</Row>
		</ColumnGroup>
	);

	const footerColumnGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={9}/>
			</Row>
		</ColumnGroup>
	);

	function statusOrcamento(status, osio) {
		osio.status = status;
		if (["APROVADO", "RECUSADO"].includes(status)) {
			osio.dataConclusao = moment().format("YYYY-MM-DDTHH:mm:ss");
			osio.concluidoPor = usuario;
			osio.aprovadoOuRecusadoPor = usuario.email;
		}
		handleChange({name: "orcamentos", value: item.orcamentos});
	}

	const handleOpenModalAprovacao = async (_, eventoDaLinha) => {
		if (ordemServico.contrato?.valorFranquia) {
			if (!ordemServico.dataConsumoFranquia?.length) {
				showDialog(<InformationDialog header="Aviso" message="A data de consumo da franquia é obrigatória antes da aprovação do orçamento."/>);
				return;
			}
			const resposta = await contratoService.buscarPeriodoConsumo({
				contrato: ordemServico.contrato.id,
				ordemServico: ordemServico.id,
				dataReferencia: ordemServico.dataConsumoFranquia,
				valorTotal: ordemServico.valorFranquia
			});
			switch (resposta.status) {
				case "EXCEDE":
					showDialog(<InformationDialog header="Informação" message="O valor desta OS excede o limite do contrato para o período de consumo da franquia para a data selecionada."/>);
					return;
				case "FORA":
					showDialog(<InformationDialog header="Informação" message="Não foi encontrado período de consumo de franquia para esta OS na data selecionada."/>);
					return;
				default:
					setContratoPeriodoConsumo({id: resposta.periodoConsumo});
					break;
			}
		}
		setModalOrcamentoAprovacao(true);
		setOrcamentoIndex(eventoDaLinha.rowIndex);
	}

	return (
		<BlockUI blocked={uiBlocked}>
			<PanelContent>
				<div className="col-12">
					<DataTable rows={10}
					           value={item.orcamentos}
					           headerColumnGroup={headerColumnGroup}
					           footerColumnGroup={footerColumnGroup}
					           header={isStacked() ? null : orcamentoControlSet}
					           footer={isStacked() ? null : orcamentoControlSet}
					>
						<Column header="Nº" style={{width: "7em"}} field="numero" body={osio => <div style={{textAlign: "right"}}>{osio.numero}</div>}/>
						<Column header="Status" style={{width: "5em", textAlign:'center'}} field="status" body={osio => labelOrcamentoStatus[osio.status]}/>
						<Column header="Responsável" field="responsavel" body={osio => osio.responsavel?.nome}/>
						<Column header="Forma de faturamento" style={{width: "5em", textAlign:'center'}} field="formaFaturamento" body={osio => labelOrcamentoFormaFaturamento[osio.formaFaturamento]}/>
						<Column header="Cliente de faturamento" field="clienteFaturamento"/>
						<Column header="Criado Em" style={{width: "13em"}} field="registro" body={osio => <div style={{textAlign: "center"}}>{moment(osio.registro).format("DD/MM/YYYY HH:mm")}</div>}/>
						<Column header="Concluído Em" field="dataConclusao" body={osio => osio.dataConclusao && <div style={{textAlign: "center"}}>{moment(osio.dataConclusao).format("DD/MM/YYYY HH:mm")}</div>}/>
						<Column header="Aprovado/Reprovado por" field="aprovadoOuRecusadoPor" />
						<Column 
							header="Ações" 
							style={{textAlign: "center",width: "8em" }} 
							field="arquivo" 
							body={(osio, c) => (
								<div className="flex justify-content-center">
									{!!roles.OSOA && osio.orcamento && ["GERADO", "ENVIADO"].includes(osio.status) 
										? <Button 
											success 
											icon="fa-solid fa-check" 
											title='Aprovar orçamento'
											onClick={() => handleOpenModalAprovacao(osio, c)}
										/> 
										: null
									}
									{(osio.orcamento && ["GERADO", "ENVIADO"].includes(osio.status)) || (osio.orcamento && osio.status === "APROVADO" && !!roles.OSOA) ? <Button danger icon="fa-solid fa-cancel" onClick={() => statusOrcamento("RECUSADO", osio)}/> : null}
									<Button disabled={!osio.orcamento || blocked} loading={blocked === c.rowIndex + 1} icon="fa-solid fa-download" onClick={() => baixarOrcamento(c.rowIndex, osio)}/>
									{
										osio.status === "ENVIADO" &&
										<Button 
											title='Reenviar orçamento' 
											warning 
											disabled={!osio.orcamento || blocked} 
											loading={blocked === c.rowIndex + 1} 
											icon="fa-solid fa-repeat" 
											onClick={() => reenviarOrcamento(c.rowIndex, osio)}
										/>
									}
								</div>
							)}/>
					</DataTable>
				</div>
			</PanelContent>
			{
				ordemServico.itens[0].orcamentos.length > 0 &&
				<DialogAprovacaoOrcamento 
					visible={modalOrcamentoAprovacao}
					orcamentoIndex={orcamentoIndex}
					itemIndex={index}
					setVisible={setModalOrcamentoAprovacao}
					ordemServico={ordemServico}
					handleChange={handleChange}
					setOrdemServico={setOrdemServico}
					contratoPeriodoConsumo={contratoPeriodoConsumo}
				/>
			}
		</BlockUI>
	);

});
