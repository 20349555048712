import React, {useContext, useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {PanelContent, PanelFooter} from "../../components/panel";
import {Dropdown} from "../../components/dropdown";
import {isEntityRequired} from "../../utils/fieldValidator";
import {UsuarioAutoComplete} from "../../components/autocomplete/usuarioAutoComplete";
import {InputText} from "../../components/inputtext";
import {Button} from "../../components/button";
import {ordemServicoService} from "../../service/ordemServicoService";
import {useBreakpoint} from "../../context/BreakpointContext";
import {useNavigate} from "react-router-dom";
import {ordemServicoEtapaService} from "../../service/ordemServicoEtapaService";
import {InformationDialog} from "../../components/dialog/InformationDialog";
import {DialogContext} from "../../utils/dialogContext";
import {useAuth} from "../../context/AuthContext";

export function EditarAcompanhamento({compras, ordemServico}) {

    const {usuario} = useAuth();
    const navigate = useNavigate();
    const {showDialog} = useContext(DialogContext);
    const {breakpoint} = useBreakpoint();
    const isLargeDevice = breakpoint === "lg" || breakpoint === "md";
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({});
    const [optionsEtapas, setOptionsEtapas] = useState([]);
    const [acompanhamento, setAcompanhamento] = useState(ordemServicoService.criarAcompanhamento());

    useEffect(() => {
        const acompanhamento = {};
        const optionsEtapas = ordemServico.etapas.filter(e => ordemServico.etapa.proximas.some(p => e.sequencia === p)).map(e => ({label: e.descricao, value: e})) || [];
        if (compras) {
            acompanhamento.etapa = optionsEtapas.filter(e => e.value.status === "COMPRAS")[0].value;
        } else {
            if (ordemServico.etapa.proximaPadrao) {
                const proximaOption = optionsEtapas.filter(e => e.value.sequencia === ordemServico.etapa.proximaPadrao);
                if (proximaOption.length) {
                    acompanhamento.etapa = proximaOption[0].value;
                }
            }
        }
        if (optionsEtapas.length === 1) {
            acompanhamento.etapa = optionsEtapas[0].value;
        }
        setOptionsEtapas(optionsEtapas);
        setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, ...acompanhamento}));
    }, []);

    function handleChange(event) {
        setAcompanhamento(prevAcompanhamento => ({...prevAcompanhamento, [event.name]: event.value}));
    }

    async function handleSalvar() {
        let messages = ordemServicoEtapaService.validarAcompanhamento(acompanhamento);
        if (acompanhamento.etapa?.status === "FATURAMENTO") {
            if (ordemServico.operacao === "LABORATORIO" && !ordemServico.notaFiscal?.numero) {
                showDialog(<InformationDialog header="Informação" message="Não é possível encaminhar a ordem de serviço para faturamento sem nota fiscal de entrada."/>);
                return;
            }
        }
        if (messages.isEmpty()) {
            setLoading(true);
            acompanhamento.atribuinte = usuario;
            ordemServico.acompanhamentos.push(acompanhamento);
            ordemServico.etapa = acompanhamento.etapa;
            ordemServico.responsavel = acompanhamento.responsavel;
            if (acompanhamento.etapa?.status) {
                ordemServico.status = acompanhamento.etapa.status;
            }
            await ordemServicoService.salvar(ordemServico);
            handleVoltar();
        } else {
            setMessages(messages);
            showDialog(<InformationDialog header="Informação" message="Alguns campos obrigatórios não estão preenchidos corretamente. Por favor, corrija-os."/>);
        }
    }

    function handleVoltar() {
        navigate(-1);
    }

    return (
        <Panel header={`Detalhes de Acompanhamento da Ordem de Serviço nº ${ordemServico.numero}`}>
            <PanelContent>
                <Dropdown onBlur={() => {
                    messages.etapa = isEntityRequired(acompanhamento.etapa);
                    setMessages({...messages});
                }} required invalid={messages.etapa} emptyMessage="Nenhuma etapa disponível" options={optionsEtapas} col={4} label="Etapa" name="etapa" value={acompanhamento.etapa} onChange={handleChange}/>
                <UsuarioAutoComplete onBlur={() => {
                    messages.responsavel = isEntityRequired(acompanhamento.responsavel);
                    setMessages({...messages});
                }} invalid={messages.responsavel} required col={8} label="Responsável" name="responsavel" value={acompanhamento.responsavel} onChange={handleChange}/>
                <InputText multiline style={{height: "13em"}} col={12} name="observacoes" label="Observações" value={acompanhamento.observacoes} onChange={handleChange}/>
            </PanelContent>
            <PanelFooter>
                <Button disabled={loading} loading={loading} icon="pi pi-save" success label={isLargeDevice ? "Salvar" : null} onClick={handleSalvar} autowidth={!isLargeDevice.toString()}/>
                <Button disabled={loading} icon="pi pi-arrow-left" secondary label={isLargeDevice ? "Voltar" : null} onClick={handleVoltar} autowidth={!isLargeDevice.toString()}/>
            </PanelFooter>
        </Panel>
    );

}
