import React, {useCallback, useState} from "react";
import {useDialog} from "../../utils/dialogContext";
import {Dropdown} from "../../components/dropdown";
import {InputNumber} from "../../components/inputnumber";
import {EquipamentoAutoComplete} from "../../components/autocomplete/equipamentoAutoComplete";
import {DataTable} from "../../components/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Button} from "../../components/button";
import {contratoService, optionsModoPeriodicidade,} from "../../service/contratoService";
import {ConfirmDialogV2} from "../../components/confirmdialog";
import {isBiggerThanZero, isEntityRequired, isRequired,} from "../../utils/fieldValidator";
import {CadastrarEquipamentosLote} from "./CadastrarEquipamentosLote";
import {FilterMatchMode} from "primereact/api";
import {debounce} from "lodash";
import {InputText as PInputText} from "primereact/inputtext";

const headersTipo = {
	produtos: "Produto",
	servicos: "Serviço",
	equipamentos: "Equipamento",
};

function emptyParams() {
	return {
		modoPeriodicidade: null,
		periodicidade: null,
		quantidade: null,
		quantidadeMauUso: null,
		quantidadeEquipamento: null,
	};
}

export function EditarContratoCoberturaEquipamento({messages, setMessages, contrato, setContrato}) {
	const {showDialog} = useDialog();
	const [itensSelecionados, setItensSelecionados] = useState([]);
	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState(emptyParams());
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS},
	});
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	function adicionarItem() {
		contrato.equipamentos.unshift({
			...contratoService.criarItem(),
			quantidade: contrato.padraoEquipamentoQuantidade,
			quantidadeMauUso: contrato.padraoEquipamentoQuantidadeMauUso,
		});
		setContrato({...contrato});
	}
	const debouncedGlobalFilterChange = useCallback(
		debounce((value) => {
			const _filters = {...filters};
			_filters["global"].value = value;
			setFilters(_filters);
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
			}, 200); // Atraso de 200 milissegundos
		}, 500), // Debounce de 500 milissegundos
		[filters]
	);
	const onGlobalFilterChange = useCallback((e) => {
			const value = e.target.value;
			setGlobalFilterValue(value);
			debouncedGlobalFilterChange(value);
		},
		[debouncedGlobalFilterChange]
	);
	function removerItens() {
		showDialog(
			<ConfirmDialogV2
				message="Tem certeza de que deseja remover os itens selecionados?"
				onYes={() => {
					contrato.equipamentos = contrato.equipamentos.filter(
						(osi) =>
							!itensSelecionados.some(
								(i) =>
									(i._key && osi._key === i._key) || (i.id && osi.id === i.id)
							)
					);
					setContrato({...contrato});
					setItensSelecionados([]);
				}}
			/>
		);
	}
	function handleChangeContrato(event) {
		setContrato((prevContrato) => ({
			...prevContrato,
			[event.name]: event.value,
		}));
	}
	function handleChange(event) {
		contrato.equipamentos[event.index][event.name] = event.value;
		setContrato({...contrato});
	}
	function handleChangeParams(event) {
		setParams({...params, [event.name]: event.value});
		for (const item of contrato.equipamentos) {
			item[event.name] = event.value;
		}
		setContrato({...contrato});
	}
	function onBlurParams() {
		setParams(emptyParams());
	}
	function cadastrarNovoEquipamento() {
	}
	const controlSet = (
		<div className="flex">
			<Button
				icon="pi pi-plus"
				onClick={adicionarItem}
			/>
			<Button
				disabled={!itensSelecionados?.length}
				onClick={removerItens}
				danger
				icon="pi pi-minus"
			/>
			<div className="ml-1 p-inputgroup">
				<div className="p-inputgroup-addon">
					<i className="pi pi-search"/>
				</div>
				<PInputText
					value={globalFilterValue}
					onChange={onGlobalFilterChange}
					placeholder="Pesquise por número de série ou descrição"
				/>
			</div>
		</div>
	);
	function handleCadastrarEquipamentosLote() {
		showDialog(
			<CadastrarEquipamentosLote
				onModalClose={(equipamentos) => {
					if (equipamentos?.length) {
						if (!contrato.equipamentos) {
							contrato.equipamentos = [];
						}
						contrato.equipamentos = contrato.equipamentos.filter(
							(e) => !equipamentos.some((x) => x.id === e.id)
						);
						for (const equipamento of equipamentos) {
							contrato.equipamentos.unshift({equipamento});
						}
						setContrato({...contrato});
					}
				}}
				contrato={contrato}
			/>
		);
	}
	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={3} header={controlSet}/>
				<Column
					header={
						<div style={{textAlign: "right"}}>
							Padrão
							<br/>
							Novos Equipamentos
						</div>
					}
				/>
				<Column
					header={
						<InputNumber
							onChange={handleChangeContrato}
							reduced
							value={contrato.padraoEquipamentoQuantidade}
							name="padraoEquipamentoQuantidade"
						/>
					}
				/>
				<Column
					header={
						<InputNumber
							onChange={handleChangeContrato}
							reduced
							value={contrato.padraoEquipamentoQuantidadeMauUso}
							name="padraoEquipamentoQuantidadeMauUso"
						/>
					}
				/>
			</Row>
			<Row>
				<Column
					rowSpan={3}
					selectionMode="multiple"
					style={{width: "3em"}}
				/>
				<Column rowSpan={3} header={headersTipo.equipamentos}/>
				<Column
					rowSpan={2}
					colSpan={2}
					header={<div style={{textAlign: "center"}}>Periodicidade</div>}
				/>
				<Column
					colSpan={2}
					header={<div style={{textAlign: "center"}}>Limites</div>}
				/>
			</Row>
			<Row>
				<Column header="Total"/>
				<Column
					header={
						<span>
              Por
              <br/>
              Mau Uso
            </span>
					}
				/>
			</Row>
			<Row>
				<Column
					style={{width: "8em"}}
					header={
						<InputNumber
							reduced
							onBlur={onBlurParams}
							name="periodicidade"
							value={params.periodicidade}
							onChange={handleChangeParams}
						/>
					}
				/>
				<Column
					style={{width: "15em"}}
					header={
						<Dropdown
							reduced
							onBlur={onBlurParams}
							options={optionsModoPeriodicidade}
							name="modoPeriodicidade"
							value={params.modoPeriodicidade}
							onChange={handleChangeParams}
						/>
					}
				/>
				<Column
					style={{width: "8em"}}
					header={
						<InputNumber
							reduced
							onBlur={onBlurParams}
							name="quantidade"
							value={params.quantidade}
							onChange={handleChangeParams}
						/>
					}
				/>
				<Column
					style={{width: "8em"}}
					header={
						<InputNumber
							reduced
							onBlur={onBlurParams}
							name="quantidadeMauUso"
							value={params.quantidadeMauUso}
							onChange={handleChangeParams}
						/>
					}
				/>
			</Row>
		</ColumnGroup>
	);
	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column colSpan={11} footer={controlSet}/>
			</Row>
		</ColumnGroup>
	);
	function validarItens() {
		messages.equipamentos = [...Array(contrato.equipamentos.length)].map(
			(_, i) => ({
				periodicidade:
					contrato.equipamentos[i].modoPeriodicidade === "CONTRATO"
						? null
						: isBiggerThanZero(
						contrato.equipamentos[i].periodicidade,
						"Obrigatório"
						),
				modoPeriodicidade: isRequired(
					contrato.equipamentos[i].modoPeriodicidade
				),
				equipamento: isEntityRequired(contrato.equipamentos[i].equipamento),
			})
		);
		setMessages({...messages});
	}
	return (
		<>
			<div className="py-0 col-12 flex justify-content-end">
				<Button
					className="inline-block w-20rem"
					label="Cadastrar Equipamentos"
					success
					onClick={handleCadastrarEquipamentosLote}
				/>
			</div>
			<div className="col-12">
				<DataTable
					selectionMode="checkbox"
					selection={itensSelecionados}
					onSelectionChange={(e) => setItensSelecionados(e.value)}
					footer={null}
					headerColumnGroup={headerGroup}
					footerColumnGroup={footerGroup}
					value={contrato.equipamentos}
					dataKey={"id"}
					filters={filters}
					globalFilterFields={[
						"equipamento.produto.codigo",
						"equipamento.produto.autoComplete",
						"equipamento.produto.descricao",
						"equipamento.serial",
					]}
				>
					<Column selectionMode="multiple"/>
					<Column
						style={{flexDirection: "column", alignItems: "start"}}
						field="tipo"
						header=""
						body={(cc, c) => (
							<EquipamentoAutoComplete
								invalid={messages.equipamentos?.[c.rowIndex]?.equipamento}
								col={12}
								index={c.rowIndex}
								name="equipamento"
								onBlur={validarItens}
								value={cc.equipamento}
								onChange={handleChange}
								label={null}
								repetidos={contrato?.equipamentos?.map((equipamento) => {
									return equipamento.equipamento?.serial;
								})}
							/>
						)}
					/>
					<Column
						style={{
							flexDirection: "column",
							alignItems: "start",
							width: "8em",
						}}
						field="periodicidade"
						header="&nbsp;"
						body={(cc, c) => (
							<InputNumber
								invalid={messages.equipamentos?.[c.rowIndex]?.periodicidade}
								onBlur={validarItens}
								disabled={cc.modoPeriodicidade === "CONTRATO"}
								label={null}
								value={
									cc.modoPeriodicidade === "CONTRATO" ? "" : cc.periodicidade
								}
								index={c.rowIndex}
								name="periodicidade"
								onChange={handleChange}
							/>
						)}
					/>
					<Column
						style={{flexDirection: "column", alignItems: "start"}}
						field="modoPeriodicidade"
						header="Periodicidade"
						body={(cc, c) => (
							<Dropdown
								invalid={
									messages.equipamentos?.[c.rowIndex]?.modoPeriodicidade
								}
								onBlur={validarItens}
								options={optionsModoPeriodicidade}
								label={null}
								value={cc.modoPeriodicidade}
								index={c.rowIndex}
								name="modoPeriodicidade"
								onChange={handleChange}
							/>
						)}
					/>
					<Column
						style={{flexDirection: "column", alignItems: "start"}}
						field="quantidade"
						header="Total"
						body={(cc, c) => (
							<InputNumber
								invalid={messages.equipamentos?.[c.rowIndex]?.quantidade}
								onBlur={validarItens}
								label={null}
								value={cc.quantidade}
								index={c.rowIndex}
								name="quantidade"
								onChange={handleChange}
							/>
						)}
					/>
					<Column
						style={{flexDirection: "column", alignItems: "start"}}
						field="quantidadeMauUso"
						header="Por Mau Uso"
						body={(cc, c) => (
							<InputNumber
								invalid={
									messages.equipamentos?.[c.rowIndex]?.quantidadeMauUso
								}
								onBlur={validarItens}
								label={null}
								value={cc.quantidadeMauUso}
								index={c.rowIndex}
								name="quantidadeMauUso"
								onChange={handleChange}
							/>
						)}
					/>
				</DataTable>
			</div>
		</>
	);
}
