import React from "react";
import {buildValidator, isBiggerThanZero, isEmpty, isEntityRequired, isRequired} from "../utils/fieldValidator";
import {baseService} from "./baseService";
import {produtoService} from "./produtoService";
import {equipamentoService} from "./equipamentoService";
import {arquivoService} from "./arquivoService";
import {seloIpemService} from "./seloIpemService";
import {api} from "./api";
import moment from "moment";
import {ordemServicoService, statusConsumo} from "./ordemServicoService";
import {dateTimeNow} from "../utils/dateUtils";

const resourceUrl = "/ordens-servico/itens";

export const ordemServicoItemService = {
	criar() {
		return {
			id: null,
			_key: Math.random(),
			equipamento: {
				serial: "",
				produto: null
			},
			flags: [],
			pecas: [],
			servicos: [],
			fotos: [],
			lacresRetirados: null,
			lacresAfixados: null,
			acessorios: [],
			movimentacoes: [],
			orcamentos: [],
			comprovantes: [],
			selosIpem: []
		};
	},
	criarPeca() {
		return {
			id: null,
			_key: Math.random(),
			produto: null,
			quantidade: 1,
			valorTotal: 0,
			valorUnitario: 0,
			valorTabela: 0,
			valorFranquia: 0,
			estoque: "NOVAS",
			status: "SOLICITADO",
			adicionadoPor: null,
			estoqueConsumoNovas: null,
			estoqueConsumoRecondicionadas: null,
			estoqueConsumoConfirmado: false
		};
	},
	criarServico() {
		return {
			id: null,
			_key: Math.random(),
			servico: null,
			quantidade: 1,
			valorTotal: 0,
			valorUnitario:0,
			valorTabela: 0,
			valorFranquia: 0,
			status: "PENDENTE",
			inicio: moment().format("YYYY-MM-DDTHH:mm:ss"),
			_equipamentoInteiro: true
		};
	},
	criarOrcamento() {
		return {
			id: null,
			_key: Math.random(),
			responsavel: null,
			destinatarios: [],
			orcamento: null,
			status: "GERADO"
		}
	},
	criarMovimentacao() {
		return {
			id: null,
			_key: Math.random(),
			registro: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
			responsavel: null,
			prateleira: null,
			movimento: "ENTRADA"
		};
	},
	validar: (item, ordemServico) => {
		const messages = buildValidator();
		messages.pecas = [...Array(item.pecas.length)].map((_, i) => ({}));
		messages.servicos = [...Array(item.servicos.length)].map((_, i) => ({}));
		messages.movimentacoes = [...Array(item.movimentacoes.length)].map((_, i) => ({}));
		switch (ordemServico.tipo) {
			case "PROJETO":
				messages.equipamento = isRequired(item.equipamento?.serial);
				break;
			default:
				messages.equipamento = isRequired(item.equipamento?.serial) || isEntityRequired(item.equipamento?.produto);
				break;
		}
		for (let j = 0; j < item.pecas.length; ++j) {
			messages.pecas[j].isEmpty = isEmpty(messages.pecas[j]);
			messages.pecas[j].produto = isEntityRequired(item.pecas[j].produto);
			messages.pecas[j].quantidade = isBiggerThanZero(item.pecas[j].quantidade, "Campo obrigatório");
			if (ordemServico.status !== "CANCELADA" && (statusConsumo.includes(ordemServico.etapa?.status) || (statusConsumo.includes(ordemServico?.status) && ordemServico._status === "manual"))) {
				messages.pecas[j].status = (["SOLICITADO", "COMPRAS"].includes(item.pecas[j].status) && item.pecas[j].estoque === "NOVAS" ? "Posição incorreta" : null);
			}
		}
		for (let j = 0; j < item.servicos.length; ++j) {
			messages.servicos[j].isEmpty = isEmpty(messages.servicos[j]);
			messages.servicos[j].servico = isEntityRequired(item.servicos[j].servico);
			messages.servicos[j].quantidade = isBiggerThanZero(item.servicos[j].quantidade, "Campo obrigatório");
		}
		return messages;
	},
	async salvarDependencias(item) {
		if (item.equipamento?.produto?._alterado) {
			const produto = await produtoService.buscar(item.equipamento.produto.id);
			item.equipamento.produto = await produtoService.salvar({...produto, ...item.equipamento.produto, postBack: true});
		}
		if (item.equipamento?._alterado) {
			item.equipamento = await equipamentoService.salvar({...item.equipamento, postBack: true});
		}
		item.fotos = item.fotos.filter(f => f.id || f.fileName);
		for (const foto of item.fotos) {
			if (!foto.id) {
				foto.id = (await ordemServicoService.salvarArquivo({...foto, postBack: true})).id;
			}
		}
		for (const comprovante of item.comprovantes) {
			if (!comprovante.id) {
				comprovante.arquivo = await arquivoService.salvar({...comprovante.arquivo, postBack: true});
			}
		}
		item.postBack = true;
		if (item.ordemServico?.notaFiscal?.itens?.length) {
			const itensNotaFiscal = item.ordemServico.notaFiscal.itens.filter(nfi => nfi.equipamento?.id === item.equipamento?.id);
			if (itensNotaFiscal?.length) {
				item.valorNotaFiscal = itensNotaFiscal[0].valor;
			}
		}
		const selosIpem = [...item.selosIpem];
		item = await api.post(resourceUrl, item).then(response => response.data);
		if (selosIpem?.length) {
			for (let seloIpem of selosIpem) {
				if (seloIpem.status === "LIVRE") {
					seloIpem = await seloIpemService.buscar(seloIpem.id);
					seloIpem.status = "UTILIZADO";
					seloIpem.item = {
						id: item.id
					};
					seloIpem = await seloIpemService.buscar((await seloIpemService.salvar({...seloIpem, postBack: true})).id);
				}
			}
		}
		item.selosIpem = selosIpem;
		return item;
	},
	async patch(path) {
		return await api.patch(`${resourceUrl}${path}`);
	},
	async listarItensLaudo(id) {
		return await api.get(`${resourceUrl}/laudo/${id}`).then(response => response.data);
	},
	async existeEquipamentoEmOrdemServico(ordemServico, equipamento) {
		if (ordemServico) {
			return await api.get(`${resourceUrl}/exists?ordemServico=${ordemServico}&equipamento=${equipamento}`).then(response => response.data);
		} else {
			return false;
		}
	},
	...baseService(resourceUrl),
    async listarPecas(numero) {
        return await api.get(`${resourceUrl}/pecas/${numero}`).then(response => response.data);
    },
	async salvarPecas(numero, pecas) {
		return await api.post(`${resourceUrl}/pecas/${numero}`, pecas).then(response => response.data);
	},
    async buscarCompras(params) {
		const query = [];
		if (params.produto?.id) query.push(`produto=${params.produto.id}`);
		if (params.ordemServico) query.push(`ordemServico=${params.ordemServico}`);
		if (params.quantidades?.length) query.push(`quantidades=${params.quantidades}`);
		if (params.responsavel?.id) query.push(`responsavel=${params.responsavel.id}`);
		return await api.get(`${resourceUrl}/pecas/compras?${query ? query.join("&") : ""}`).then(response => response.data);
    },
	async baixarCompras(query) {
		return await api.get(`${resourceUrl}/pecas/baixar-compras?${query ? query.join("&") : ""}`).then(response => response.data);
    }
};

export const ordemServicoItemPecaStatus = {
	SOLICITADO: <i title="Solicitado" className="fas fa-file" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#2196F3"}}/>,
	DISPONIVEL: <i title="Disponível" className="fas fa-file-circle-check" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#673AB7"}}/>,
	COMPRAS: <i title="Compras" className="fas fa-shopping-cart" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#673AB7"}}/>,
	MOVIMENTADO: <i title="Entregue" className="fas fa-check-circle" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#4CAF50"}}/>,
	CANCELADO: <i title="Cancelado/Devolvido" className="fas fa-cancel" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#F44336"}}/>,
	LIBERADO: <i title="Liberado" className="fas fa-check-square" style={{display: "inline-block", width: "20px", textAlign: "center", color: "#009688"}}/>
};

export const labelOrdemServicoItemPecaStatus = {
	SOLICITADO: "Solicitado",
	COMPRAS: "Compras",
	MOVIMENTADO: "Movimentado",
	CANCELADO: "Cancelado",
	LIBERADO: "Liberado"
};

export const optionsOrdemServicoItemPecaStatus = [
	{label: <><i className="fas fa-file" title="Solicitado" style={{color: "#2196F3"}}/> Solicitado</>, value: "SOLICITADO"},
	{label: <><i className="fas fa-shopping-cart" title="Compras" style={{color: "#673AB7"}}/> Compras</>, value: "COMPRAS"},
	{label: <><i className="fas fa-check-circle" title="Entregue" style={{color: "#4CAF50"}}/> Entregue</>, value: "MOVIMENTADO"},
	{label: <><i className="fas fa-cancel" title="Cancelado/Devolvido" style={{color: "#F44336"}}/> Cancelado</>, value: "CANCELADO"},
	{label: <><i className="fas fa-check-square" title="Liberado" style={{color: "#009688"}}/> Liberado</>, value: "LIBERADO"}
];
