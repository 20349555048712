import React, {useContext, useMemo} from "react";
import {calendarioService} from "../service/calendarioService";
import {useQuery} from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";

const CalendarioContext = React.createContext({feriados: []});

export function CalendarioProvider({children}) {
    const {usuario} = useAuth();
    const query = useQuery({
        queryKey: ["Calendario-Padrao", usuario?.id],
        queryFn: async () => {
            if (usuario?.id) {
                return await calendarioService.padrao();
            } else {
                return {feriados: []};
            }
        },
        staleTime: 60000
    });
    const value = () => ({feriados: query.data.feriados || []});
    return <CalendarioContext.Provider value={value} children={children}/>;
}

export function useCalendarioPadrao() {
    return useContext(CalendarioContext);
}
