import {buildValidator, isBiggerThanZero, isCEP, isCNPJ, isRequired, isUF} from '../utils/fieldValidator';
import {baseService} from './baseService';
import {toast} from "react-toastify";

const resourceUrl = '/empresas'


export const empresaService = {
    criar: () => {
        return {
            id: null,
            cnpj: null,
            razaoSocial: null,
            nomeFantasia: null,
            cep: null,
            uf: null,
            cidade: null,
            endereco: null,
            bairro: null,
            numero: null,
            telefone: null,
            logotipo:  null,
        };
    },
    validar: (empresa) => {
        const messages = buildValidator();
        messages.razaoSocial = isRequired(empresa.razaoSocial);
        messages.nomeFantasia = isRequired(empresa.nomeFantasia);
        messages.cnpj = isCNPJ(empresa.cnpj);
        messages.cep = isCEP(empresa.cep);
        messages.bairro = isRequired(empresa.bairro);
        messages.cidade = isRequired(empresa.cidade);
        messages.uf = isUF(empresa.uf);
        messages.endereco = isRequired(empresa.endereco);
        messages.logotipo =  isRequired(empresa.logotipo?.fileName)
        if(empresa.numero !== 'SEM NUMERO'){
            messages.numero = isBiggerThanZero(empresa.numero);
        }

        return messages;
    },
    ...baseService(resourceUrl),
    async listarEmpresas(params) {
        const query = [];
        if (params.textoDeBusca?.length) query.push(`cnpj,razaoSocial,nomeFantasia=lk=${params.textoDeBusca}`);
        if (!params.visualizarDesativados) {
            query.push("status==ATIVO");
        }
        if (!query[0]) {
            query.push("");
        }
        return await empresaService.listar([`query=${query.join(";")}`]);
    }
};
