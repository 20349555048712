import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Tree} from 'primereact/tree';
import {Button} from '../../components/button';
import {FormEdit} from '../../components/form/Edit';
import {historicoService} from '../../service/historicoService';
import {isDate, isTime, isTimestamp} from '../../utils/fieldValidator';
import {formatTime} from "../../utils/numberFormatter";

const acronyms = [
	"rg", "cpf", "nis", "cnh", "ip", "uf", "cnpj", "cei", "ie", "cep"
];

const ignoreFields = [
	"id",
	"registro",
	"versao",
	"chaveIntegracao",
	"autoComplete"
];

function iconFor(value) {
	if (value == null) return "fa-solid fa-ellipsis";
	if (value === true) return "fa-solid fa-toggle-on";
	if (value === false) return "fa-solid fa-toggle-off";
	if (isTimestamp(value)) return "fa-solid fa-calendar-days";
	if (isDate(value)) return "fa-solid fa-calendar-days";
	if (isTime(value)) return "fa-solid fa-clock";
	if (!isNaN(value)) return "fa-solid fa-asterisk";
	return "fa-solid fa-font";
}

function formatValue(value) {
	if (value == null) return "<vazio>";
	if (value === true) return "Sim";
	if (value === false) return "Não";
	if (isTimestamp(value)) return moment(value).format("DD/MM/YYYY HH:mm");
	if (isDate(value)) return moment(value).format("DD/MM/YYYY");
	if (isTime(value)) return formatTime(value);
	return value;
}

function keyAsLabel(key) {
	return key.split(/(?=[A-Z])/g).map(function(s) {
		return acronyms.includes(s) ? s.toUpperCase() : (s.charAt(0).toUpperCase() + s.slice(1));
	}).join(" ");
}

function entityLabel(entity, key, index) {
	return `${keyAsLabel(key)}: ${entity.descricao || entity.nome || index + 1}`;
}

// function mountEntityAudit(entity) {
// 	return Object.keys(entity).filter(k => !ignoreFields.includes(k)).sort((a, b) => a.localeCompare(b)).map((key, index) => {
// 		if (entity[key] != null && entity[key].id != null) {
// 			return {
// 				label:  entityLabel(entity[key], key, 0),
// 				data: key,
// 				expandedIcon: "fa-solid fa-folder-open",
// 				collapsedIcon: "fa-solid fa-folder",
// 				children: mountEntityAudit(entity[key]),
// 			};
// 		} else if (Array.isArray(entity[key])) {
// 			if (typeof(entity[key][0]) === "string") {
// 				const string = entity[key].join(", ")
// 				return {
// 					label: `${keyAsLabel(key)}: ${formatValue(string)}`,
// 					collapsedIcon: iconFor(string),
// 				}
// 			}
// 			return {
// 				label: keyAsLabel(key),
// 				data: key,
// 				expandedIcon: "fa-solid fa-folder-open",
// 				collapsedIcon: "fa-solid fa-folder",
// 				children: entity[key].sort((a, b) => a.id != null && b.id != null ? a.id - b.id : 0).map((item, index) => {
// 					if (item != null && item.id != null) {
// 						return {
// 							label: entityLabel(item, key, index),
// 							data: key,
// 							expandedIcon: "fa-solid fa-folder-open",
// 							collapsedIcon: "fa-solid fa-folder",
// 							children: mountEntityAudit(item)
// 						};
// 					} else {
// 						return {
// 							label: formatValue(item),
// 							data: key,
// 							collapsedIcon: iconFor(item)
// 						};
// 					}
// 				})
// 			};
// 		} else {
// 			return {
// 				label: `${keyAsLabel(key)}: ${formatValue(entity[key])}`,
// 				data: entity[key],
// 				collapsedIcon: iconFor(entity[key])
// 			};
// 		}
// 	});
// }

function mountEntityAudit(entity, parentKey = null) {
    return Object.keys(entity)
        .filter(k => !ignoreFields.includes(k))
        .sort((a, b) => a.localeCompare(b))
        .map(key => {
            const currentEntity = entity[key];

            const generatedKey = parentKey !== null ? `${parentKey}-${key}` : key;

            if (currentEntity != null && currentEntity.id != null) {
                return {
                    key: generatedKey,
                    label: entityLabel(currentEntity, key, 0),
                    data: key,
                    icon: "fa-solid fa-folder-open",
                    children: mountEntityAudit(currentEntity, generatedKey),
                };
            } else if (Array.isArray(currentEntity)) {
                if (typeof currentEntity[0] === "string") {
                    const joinedString = currentEntity.join(", ");
                    return {
                        key: generatedKey,
                        label: `${keyAsLabel(key)}: ${formatValue(joinedString)}`,
                        icon: iconFor(joinedString),
                    };
                }

                return {
                    key: generatedKey,
                    label: keyAsLabel(key),
                    data: key,
                    icon: "fa-solid fa-folder-open",
                    children: currentEntity
                        .sort((a, b) => (a.id != null && b.id != null ? a.id - b.id : 0))
                        .map((item, index) => {
                            if (item != null && item.id != null) {
                                return {
                                    key: `${generatedKey}-${index}`,
                                    label: entityLabel(item, key, index),
                                    data: key,
                                    icon: "fa-solid fa-folder-open",
                                    children: mountEntityAudit(item, `${generatedKey}-${index}`),
                                };
                            } else {
                                return {
                                    key: `${generatedKey}-${index}`,
                                    label: formatValue(item),
                                    data: key,
                                    icon: iconFor(item),
                                };
                            }
                        }),
                };
            } else {
                return {
                    key: generatedKey,
                    label: `${keyAsLabel(key)}: ${formatValue(currentEntity)}`,
                    data: currentEntity,
                    icon: iconFor(currentEntity),
                };
            }
        });
}
export function Historico({path}) {

    let {id} = useParams();

    useEffect(() => {
        if (!id) return;
        historicoService.versoes(path, id).then((history) => {
			history = history.map(audit => mountEntityAudit(audit));
			let audit = history.length > 0 ? history[0] : null;
			setHistoryObject({history, audit});
		});
        historicoService.revisoes(path, id).then((revisions) => {
			let rev = revisions.length > 0 ? revisions[0] : null;
			setRevisionsObject({revisions, rev});
		});
    }, [id, path]);

	const [revisionsObject, setRevisionsObject] = useState(
		{   
            revisions: [],
            rev: null,
        }
	);

	const [historyObject, setHistoryObject] = useState(
		{   
			history: [],
			audit: null,
        }
	);

    const [index, setIndex] = useState(0);

	function incIndex(value) {
		let currentIndex = value !== undefined ? value : index + 1;
		setHistoryObject({...historyObject, audit: historyObject.history[currentIndex]});
		setRevisionsObject({...revisionsObject, rev: revisionsObject.revisions[currentIndex]});
		setIndex(currentIndex);
	}

	function decIndex(value) {
		let currentIndex = value !== undefined ? value : index - 1;
		setHistoryObject({...historyObject, audit: historyObject.history[currentIndex]});
		setRevisionsObject({...revisionsObject, rev: revisionsObject.revisions[currentIndex]});
		setIndex(currentIndex);
	}

    return (
        <FormEdit podeDeletar={false} podeSalvar={false} descricao="Histórico de Alterações">
			<div className="col-12">
				{revisionsObject.rev != null ? <div><i className="fa-solid fa-info-circle" /> Revisão feita por <b>{revisionsObject.rev.usuario || "sistema"}</b>{` às ${moment(revisionsObject.rev.revisionDate).format("DD/MM/YYYY HH:mm:ss")}`}</div> : null}
			</div>
			<div className="col-12">
				{console.log(historyObject.audit)}
				<Tree value={historyObject.audit} selectionMode="multiple"/>
			</div>
			<div style={{textAlign: "center"}} className="col-8 col-offset-2">
				<Button secondary disabled={index <= 0} autoWidth icon="fa-solid fa-fast-backward" onClick={() => decIndex(0)} />
				<Button secondary disabled={index <= 0} autoWidth icon="fa-solid fa-step-backward" onClick={() => decIndex()} />
				<Button secondary disabled={index > historyObject.history.length - 2} autoWidth icon="fa-solid fa-step-forward" onClick={() => incIndex()} />
				<Button secondary disabled={index > historyObject.history.length - 2} autoWidth icon="fa-solid fa-fast-forward" onClick={() => incIndex(revisionsObject.revisions.length - 1)} />
			</div>
			<div className="col-2" style={{textAlign: "right"}}>{`${index + 1} de ${revisionsObject.revisions.length}`}</div>
		</FormEdit>
    );

}
